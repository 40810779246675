import React, { useState } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../login/PrivateRoute";
// Components
import Category from "./Category";
import PostList from "../profile/PostList";
import ForYou from "./ForYou";
import SinglePost from "../profile/SinglePost";
import SinglePoll from "../statuspolls/SinglePoll";
import SingleStatus from "../statuspolls/SingleStatus";
// import SingleStatus from '../statuspolls/SingleStatus';
import CommentList from "../profile/CommentList";
import Profile from "../../Profile";
import NotificationsList from "../notifications/NotificationsList";
import TrendingPage from "../profile/TrendingPage";

const PostsContainer = (props) => {
  const [commentSubmit, setCommentSubmit] = useState(false);

  return (
    <div>
      <Switch>
        <PrivateRoute exact path="/home/activity">
          <PostList />
        </PrivateRoute>
        <PrivateRoute exact path="/home/notifications">
          <NotificationsList
            userFollowing={props.userFollowing}
            user={props.user}
            topics={props.topics}
            categories={props.categories}
            allLikes={props.allLikes}
            userPosts={props.userPosts}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/home/trending">
          <TrendingPage
            userFollowing={props.userFollowing}
            user={props.user}
            topics={props.topics}
            categories={props.categories}
            allLikes={props.allLikes}
            userPosts={props.userPosts}
            trendingPosts={props.trendingPosts}
          />
        </PrivateRoute>
        <PrivateRoute exact path={`/home/category/:id`}>
          <Category
            categories={props.categories}
            allLikes={props.allLikes}
            user={props.user}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/home/recommended">
          <ForYou
            categories={props.categories}
            topics={props.topics}
            userPosts={props.userPosts}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/home/post/:id">
          <SinglePost
            commentSubmit={commentSubmit}
            setCommentSubmit={setCommentSubmit}
          />
          <CommentList
            commentSubmit={commentSubmit}
            setCommentSubmit={setCommentSubmit}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/home/poll/:id">
          <SinglePoll />
        </PrivateRoute>
        <PrivateRoute exact path="/home/status/:id">
          <SingleStatus />
        </PrivateRoute>
        <PrivateRoute exact path="/home/profile/:user_name">
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path="/home/profile/:user_name/liked">
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path="/home/profile/:user_name/polls">
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path="/home/profile/:user_name/sources">
          <Profile />
        </PrivateRoute>
      </Switch>
    </div>
  );
};

export default PostsContainer;
