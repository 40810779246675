import React, { useState } from "react";
import { connect } from "react-redux";
import { addTopicPost } from "../../redux/actions";
import axiosWithAuth from "../../utils/axiosWithAuth";
import CheckBox from "../../assets/icons/CheckBox";
import XBox from "../../assets/icons/XBox";

const CategoryPostForm = (props) => {
  const [inputPost, setInputPost] = useState({
    vote: null,
    post_body: "",
    user_id: localStorage.getItem("user_id"),
  });

  const handleChange = (e) => {
    setInputPost({ ...inputPost, [e.target.name]: e.target.value });
    console.log(inputPost);
  };
  const handleRadio = (e) => {
    setInputPost({
      ...inputPost,
      [e.target.name]: e.target.value === "true" ? true : false,
    });
    console.log(inputPost);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`/api/topics/${props.topicId}/posts`, inputPost)
      .then((res) => {
        console.log("add user post response", res);
        !props.postSuccess
          ? props.setPostSuccess(true)
          : props.setPostSuccess(false);
      })
      .catch((err) => {
        console.log("saved posts error", err);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="topic-form">
      {/* {topic.topic_body} */}
      <label className="topic-icon">
        <input
          className="topic-card-vote"
          name="vote"
          value="false"
          type="radio"
          checked={inputPost.vote === false}
          onChange={handleRadio}
          required
        />
        <XBox
          className="topic-icon"
          style={{ width: "46px", height: "46px" }}
        />
      </label>
      <label className="topic-icon" id="up-vote">
        <input
          className="topic-card-vote"
          name="vote"
          value="true"
          type="radio"
          checked={inputPost.vote === true}
          onChange={handleRadio}
          required
        />
        <CheckBox style={{ width: "46px", height: "46px" }} />
      </label>
      <textarea
        className="topic-card-text"
        placeholder="Add your opinion"
        name="post_body"
        value={inputPost.post_body}
        onChange={handleChange}
        maxLength="800"
        spellCheck={true}
        required
      />
      <button className="topic-form-submit">Submit</button>
    </form>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    topicPosts: serverReducer.topicPosts,
    isAddingTopicPosts: serverReducer.isAddingTopicPosts,
  };
};

export default connect(mapStateToProps, { addTopicPost })(CategoryPostForm);
