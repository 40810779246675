import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { Form } from "react-bootstrap";

export default function SourceForm(props) {
  const { homeUserId } = props;

  const [inputSource, setInputSource] = useState({
    source_name: "",
    source_url: "",
    source_body: "",
    user_id: homeUserId,
  });

  const handleChange = (e) => {
    setInputSource({ ...inputSource, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`api/users/${props.homeUserId}/sources`, inputSource)
      .then((res) => {
        console.log("add user source", res);
        !props.sourceSubmit
          ? props.setSourceSubmit(true)
          : props.setSourceSubmit(false);
        setInputSource({
          source_name: "",
          source_url: "",
          source_body: "",
        });
      })
      .catch((err) => {
        console.log("add status error", err);
      });
  };

  return (
    <div className="source-form-container">
      <Form className="source-form" onSubmit={handleSubmit}>
        <input
          className="source-title-input"
          placeholder="Source or Article Name"
          onChange={handleChange}
          name="source_name"
          value={inputSource.source_name}
          maxLength="200"
          spellCheck={true}
          required
        />
        <input
          className="source-title-input"
          placeholder="Link (https://)"
          onChange={handleChange}
          name="source_url"
          value={inputSource.source_url}
          maxLength="800"
          spellCheck={true}
          required
        />
        <textarea
          style={{ marginTop: "6px" }}
          className="source-form-text"
          placeholder="Description or Comments"
          onChange={handleChange}
          name="source_body"
          value={inputSource.source_body}
          maxLength="800"
          spellCheck={true}
          required
        />
        <div className="button-container">
          <button className="source-form-button">add source</button>
        </div>
      </Form>
    </div>
  );
}
