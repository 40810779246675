import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import UpVote from "../../assets/icons/UpVote";
import DownVote from "../../assets/icons/DownVote";

const OppositeOpinionScore = (props) => {
  const { user, allLikes } = props;
  const user_name = props.user.user_name;
  const postId = props.postId;
  const [dislikes, setDislikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [likes, setLikes] = useState();
  // Get likes
  useEffect(() => {
    axiosWithAuth()
      .get(`/api/posts/${postId}/likes`)
      .then((res) => {
        setLikes(res.data.length);
        const findLike = res.data.filter(
          (like) => like.user_name === user.user_name
        );
        if (findLike.length) {
          setIsLiked(true);
        } else {
          setIsLiked(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [likes, setLikes, user_name, user.user_name, postId]);

  // Get dislikes
  useEffect(() => {
    axiosWithAuth()
      .get(`/api/posts/${postId}/dislikes`)
      .then((res) => {
        setDislikes(res.data.length);
        const findDislike = res.data.filter(
          (dislike) => dislike.user_name === user.user_name
        );
        if (findDislike.length) {
          setIsDisliked(true);
        } else {
          setIsDisliked(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setDislikes, user_name, postId, user.user_name]);

  // like post
  const likePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${postId}/likes`, {
        user_name: user_name,
      })
      .then((res) => {
        setLikes(likes + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // dislike post
  const disLikePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${postId}/dislikes`, {
        user_name: user_name,
      })
      .then((res) => {
        setLikes(dislikes - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function renderUpVoteButton() {
    if (isLiked) {
      return (
        <button onClick={null} className="liked-button-style">
          <UpVote />
        </button>
      );
    } else {
      return (
        <button onClick={likePost} className="like-button-style">
          <UpVote />
        </button>
      );
    }
  }

  function renderDownVoteButton() {
    if (isDisliked) {
      return (
        <button onClick={null} className="disliked-button-style">
          <DownVote />
        </button>
      );
    } else {
      return (
        <button onClick={disLikePost} className="dislike-button-style">
          <DownVote />
        </button>
      );
    }
  }

  return (
    <div className="opposite-opinion-score">
      {renderUpVoteButton()}
      <h4 style={{ color: "#828282" }}>{likes}</h4>
      {renderDownVoteButton()}
    </div>
  );
};

export default OppositeOpinionScore;
