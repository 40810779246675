import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getUserInfo, getStatusByStatusId } from "../../redux/actions";
import StatusCard from "./StatusCard";
//Icons
import CommentIcon from "../../assets/icons/CommentIcon";
import { faChevronDown, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "react-bootstrap";

const SingleStatus = (props) => {
  const { getStatusByStatusId, status } = props;
  const history = useHistory();
  const { id } = useParams();
  const [singleStatus, setSingleStatus] = useState({
    id: "",
    status_title: "",
    status_body: "",
    created_at: "",
    user_id: "",
  });

  useEffect(() => {
    getStatusByStatusId(id);
  }, [id, getStatusByStatusId]);

  useEffect(() => {
    setSingleStatus(status[0]);
  }, [status]);

  return (
    <div>
      <div className="single-post-header-container">
        <button className="back-button-style" onClick={history.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <h3 className="single-post-header">COMMUNITY OPINION</h3>
      </div>
      {!singleStatus ? (
        <Spinner width="38" height="38" color="#FF3781" />
      ) : (
        <StatusCard
          id={singleStatus.id}
          status_title={singleStatus.status_title}
          status_body={singleStatus.status_body}
          created_at={singleStatus.created_at}
          user_id={singleStatus.user_id}
          user_name={props.user_name}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    status: serverReducer.status,
    isFetchingSingleStatus: serverReducer.isFetchingSingleStatus,
  };
};

export default connect(mapStateToProps, { getStatusByStatusId, getUserInfo })(
  SingleStatus
);
