import React from "react";

export default function VennLogo() {
  return (
    <svg width="50" height="50" viewBox="0 0 297.034 301.984">
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="0.502"
          x2="1"
          y2="0.498"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff3781" />
          <stop offset="1" stopColor="#f2857b" />
        </linearGradient>
      </defs>
      <g
        id="untitled_4_"
        data-name="untitled (4)"
        transform="translate(-159.938 -164.628)"
      >
        <g id="Group_2" data-name="Group 2" transform="translate(-16 8)">
          <g id="Group_1" data-name="Group 1">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M323.7,156.63c81.97-.41,148.85,66.92,149.27,150.25S407.18,458.2,325.21,458.61,176.36,391.7,175.94,308.36,241.74,157.04,323.7,156.63Z"
              fill="url(#linear-gradient)"
            />
          </g>
          <g id="c2VaN7KidX" transform="translate(-1 70)">
            <text
              id="VENN_THINK"
              data-name="VENN
            THINK"
              transform="translate(324.456 211.473)"
              fill="#fff"
              fontSize="79"
              fontFamily="Helvetica"
            >
              <tspan x="-109.744" y="0">
                VENN
              </tspan>
              <tspan x="-118.5" y="94.8">
                THINK
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
