import React from "react";
import { NavLink } from "react-router-dom";
import Search from "./Search";
import VennLogo from "../../assets/icons/venn-logo";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MobileNavHeader = (props) => {
  return (
    <div className="mobile-nav-header">
      <Navbar bg="light" expand="lg">
        <NavLink
          to={`/home/profile/${props.user.user_name}`}
          className="mobile-venn-logo"
        >
          <VennLogo style={{ height: "80%" }} />
        </NavLink>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="CATEGORIES" id="basic-nav-dropdown">
              <div className="categories-links">
                <NavLink
                  exact={true}
                  id="nav-trending"
                  className="nav-links"
                  activeClassName="is-active"
                  to={"/home/trending"}
                >
                  TRENDING
                  <FontAwesomeIcon
                    style={{ marginLeft: "5px" }}
                    color="#FF3781"
                    icon={faFire}
                    size="lg"
                  />
                </NavLink>
                <NavLink
                  exact={true}
                  className="nav-links"
                  activeClassName="is-active"
                  to={"/home/recommended"}
                >
                  FOR YOU
                </NavLink>
                {props.categories.map(({ category_name, id }) => (
                  <NavLink
                    key={id}
                    exact={true}
                    className="nav-links"
                    activeClassName="is-active"
                    to={`/home/category/${id}`}
                  >
                    {category_name.toUpperCase()}
                  </NavLink>
                ))}
                <NavLink
                  exact={true}
                  activeClassName="is-active"
                  className="nav-links"
                  to={"/home/activity"}
                >
                  YOUR ACTIVITY
                </NavLink>
              </div>
            </NavDropdown>
          </Nav>
          <div className="search-nav">
            <Search />
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default MobileNavHeader;
