import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../spinners/spinner";
import ResponseCard from "./ResponseCard";
// Icons
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResponseList = (props) => {
  const { responses } = props;
  const [responseList, setResponseList] = useState([]);
  // const [oppositeOpinions, setOppositeOpinions] = useState([])
  // const [sameOpinions, setSameOpinions] = useState([])
  // const [showOpposite, setShowOpposite] = useState(true)
  // const [showSame, setShowSame] = useState(false)
  // const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    setResponseList(responses);
  }, [responses]);

  // function setAgreeOpinions() {
  //   let items = await responseList;
  //   let userChoice = items.filter(res => res.user_id == localStorage.getItem('user_id'))
  //   if (items.length > 0) {
  //       return items.filter(item => item.option_id === userChoice)
  //   }
  // };
  // console.log(setAgreeOpinions())
  // function setDisagreeOpinions() {

  // };

  // function setDisagreeOpinions() {

  // };

  // function selectOppositeTab() {
  //     setShowOpposite(true)
  //     setShowAll(false)
  //     setShowSame(false)
  // };
  // function selectSameTab() {
  //     setShowOpposite(false)
  //     setShowAll(false)
  //     setShowSame(true)
  // };
  // function selectAllTab() {
  //     setShowOpposite(false)
  //     setShowAll(true)
  //     setShowSame(false)
  // };

  return (
    <>
      {!responseList ? (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner width="38" height="38" color="#FF3781" />
        </span>
      ) : responseList.length < 1 ? (
        <h4>No responses yet.</h4>
      ) : (
        <div className="response-list">
          <h3 className="response-list-header">RESPONSES</h3>
          {/* <div className='opinion-tabs'>
                    <button className='opinion-tab' style={{color: (showOpposite ? '#FF3781' : '#828282')}} onClick={selectOppositeTab}>Disagree</button>
                    <button className='opinion-tab' style={{color: (showSame ? '#FF3781' : '#828282')}} onClick={selectSameTab}>Agree</button>
                    <button className='opinion-tab' style={{color: (showAll ? '#FF3781' : '#828282')}} onClick={selectAllTab}>All</button> 
                    <Link to={`/home/post/${props.id}`} className='opposite-view-all'>View My Opinion{' '}<FontAwesomeIcon color='#FF3781' icon={faExternalLinkAlt} size='sm' /></Link>
                </div> */}
          {responseList.map((response) => {
            return (
              <ResponseCard
                key={response.id}
                options={props.options}
                response={response}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ResponseList;
