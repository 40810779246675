import axios from "axios";

const axiosWithAuth = () => {
  return axios.create({
    baseURL: "https://vennthink.herokuapp.com/",
    // baseURL: 'https://localhost:5555/',
    // withCredentials: true,
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
};

export default axiosWithAuth;
