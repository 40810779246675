const constants = {
  // AUTHORIZATION AND LOGIN
  AUTHORIZING: "AUTHORIZING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  // loginStore
  STORE_LOGIN: "STORE_LOGIN",
  // GETTING USER INFO
  FETCH_USERINFO_START: "FETCH_USERINFO_START",
  FETCH_USERINFO_SUCCESS: "FETCH_USERINFO_SUCCESS",
  FETCH_USERINFO_FAILURE: "FETCH_USERINFO_FAILURE",
  // EDITING USER INFO
  EDIT_USERINFO_START: "EDIT_USERINFO_START",
  EDIT_USERINFO_SUCCESS: "EDIT_USERINFO_SUCCESS",
  EDIT_USERINFO_FAILURE: "EDIT_USERINFO_FAILURE",
  // FINDING A USER VIA SEARCH BAR
  FIND_USERINFO_START: "FIND_USERINFO_START",
  FIND_USERINFO_SUCCESS: "FIND_USERINFO_SUCCESS",
  FIND_USERINFO_FAILURE: "FIND_USERINFO_FAILURE",
  // FOLLOW ANOTHER USER
  FOLLOW_USER_START: "FOLLOW_USER_START",
  FOLLOW_USER_SUCCESS: "FOLLOW_USER_SUCCESS",
  FOLLOW_USER_FAILURE: "FOLLOW_USER_FAILURE",
  //UNFOLLOW ANOTHER USER
  UNFOLLOW_USER_START: "UNFOLLOW_USER_START",
  UNFOLLOW_USER_SUCCESS: "UNFOLLOW_USER_SUCCESS",
  UNFOLLOW_USER_FAILURE: "UNFOLLOW_USER_FAILURE",
  // FETCH USER FOLLOWING
  FETCH_USERFOLLOWING_START: "FETCH_USERFOLLOWING_START",
  FETCH_USERFOLLOWING_SUCCESS: "FETCH_USERFOLLOWING_SUCCESS",
  FETCH_USERFOLLOWING_FAILURE: "FETCH_USERFOLLOWING_FAILURE",
  // ADDING A NEW USER
  ADD_USERINFO_START: "ADD_USERINFO_START",
  ADD_USERINFO_SUCCESS: "ADD_USERINFO_SUCCESS",
  ADD_USERINFO_FAILURE: "ADD_USERINFO_FAILURE",

  // ADDING USER PHOTO
  ADD_USERPHOTO_START: "ADD_USERPHOTO_START",
  ADD_USERPHOTO_SUCCESS: "ADD_USERPHOTO_SUCCESS",
  ADD_USERPHOTO_FAILURE: "ADD_USERPHOTO_FAILURE",
  // DELETING USER PHOTO
  DELETE_USERPHOTO_START: "DELETE_USERPHOTO_START",
  DELETE_USERPHOTO_SUCCESS: "DELETE_USERPHOTO_SUCCESS",
  DELETE_USERPHOTO_FAILURE: "DELETE_USERPHOTO_FAILURE",
  // GETTING A LIST OF CATEGORIES
  FETCH_CATEGORIES_START: "FETCH_CATEGORIES_START",
  FETCH_CATEGORIES_SUCCESS: "FETCH_CATEGORIES_SUCCESS",
  FETCH_CATEGORIES_FAILURE: "FETCH_CATEGORIES_FAILURE",
  // GETTING A SINGLE POST
  FETCH_SINGLEPOST_START: "FETCH_SINGLEPOST_START",
  FETCH_SINGLEPOST_SUCCESS: "FETCH_SINGLEPOST_SUCCESS",
  FETCH_SINGLEPOST_FAILURE: "FETCH_SINGLEPOST_FAILURE",
  // GETTING ALL POSTS FOR A TOPIC
  FETCH_TOPICPOSTS_START: "FETCH_TOPICPOSTS_START",
  FETCH_TOPICPOSTS_SUCCESS: "FETCH_TOPICPOSTS_SUCCESS",
  FETCH_TOPICPOSTS_FAILURE: "FETCH_TOPICPOSTS_FAILURE",
  // ADDING A POST TO A TOPIC
  ADD_TOPICPOST_START: "ADD_TOPICPOST_START",
  ADD_TOPICPOST_SUCCESS: "ADD_TOPICPOST_SUCCESS",
  ADD_TOPICPOST_FAILURE: "ADD_TOPICPOST_FAILURE",

  // GETTING TRENDING POSTS
  FETCH_TRENDINGPOSTS_START: "FETCH_TRENDINGPOSTS_START",
  FETCH_TRENDINGPOSTS_SUCCESS: "FETCH_TRENDINGPOSTS_SUCCESS",
  FETCH_TRENDINGPOSTS_FAILURE: "FETCH_TRENDINGPOSTS_FAILURE",
  // GETTING OPPOSITE OPINIONS
  FETCH_OPPOSITEOPINIONS_START: "FETCH_OPPOSITEOPINIONS_START",
  FETCH_OPPOSITEOPINIONS_SUCCESS: "FETCH_OPPOSITEOPINIONS_SUCCESS",
  FETCH_OPPOSITEOPINIONS_FAILURE: "FETCH_OPPOSITEOPINIONS_FAILURE",

  // GETTING PROFILE POSTS
  FETCH_PROFILEPOSTS_START: "FETCH_PROFILEPOSTS_START",
  FETCH_PROFILEPOSTS_SUCCESS: "FETCH_PROFILEPOSTS_SUCCESS",
  FETCH_PROFILEPOSTS_FAILURE: "FETCH_PROFILEPOSTS_FAILURE",
  // GETTING PROFILE STATUSES
  FETCH_PROFILESTATUSES_START: "FETCH_PROFILESTATUSES_START",
  FETCH_PROFILESTATUSES_SUCCESS: "FETCH_PROFILESTATUSES_SUCCESS",
  FETCH_PROFILESTATUSES_FAILURE: "FETCH_PROFILESTATUSES_FAILURE",
  // GETTING PROFILE SOURCES
  FETCH_PROFILESOURCES_START: "FETCH_PROFILESOURCES_START",
  FETCH_PROFILESOURCES_SUCCESS: "FETCH_PROFILESOURCES_SUCCESS",
  FETCH_PROFILESOURCES_FAILURE: "FETCH_PROFILESOURCES_FAILURE",
  // GETTING PROFILE POLLS
  FETCH_PROFILEPOLLS_START: "FETCH_PROFILEPOLLS_START",
  FETCH_PROFILEPOLLS_SUCCESS: "FETCH_PROFILEPOLLS_SUCCESS",
  FETCH_PROFILEPOLLS_FAILURE: "FETCH_PROFILEPOLLS_FAILURE",

  // GETTING SINGLE POLL
  FETCH_SINGLEPOLL_START: "FETCH_SINGLEPOLL_START",
  FETCH_SINGLEPOLL_SUCCESS: "FETCH_SINGLEPOLL_SUCCESS",
  FETCH_SINGLEPOLL_FAILURE: "FETCH_SINGLEPOLL_FAILURE",
  // GETTING SINGLE STATUS
  FETCH_SINGLESTATUS_START: "FETCH_SINGLESTATUS_START",
  FETCH_SINGLESTATUS_SUCCESS: "FETCH_SINGLESTATUS_SUCCESS",
  FETCH_SINGLESTATUS_FAILURE: "FETCH_SINGLESTATUS_FAILURE",

  // GETTING USER POSTS
  FETCH_USERPOSTS_START: "FETCH_USERPOSTS_START",
  FETCH_USERPOSTS_SUCCESS: "FETCH_USERPOSTS_SUCCESS",
  FETCH_USERPOSTS_FAILURE: "FETCH_USERPOSTS_FAILURE",
  // ADDING USER POSTS
  ADD_USERPOST_START: "ADD_USERPOST_START",
  ADD_USERPOST_SUCCESS: "ADD_USERPOST_SUCCESS",
  ADD_USERPOST_FAILURE: "ADD_USERPOST_FAILURE",
  // EDITING USER POSTS
  EDIT_USERPOST_START: "EDIT_USERPOST_START",
  EDIT_USERPOST_SUCCESS: "EDIT_USERPOST_SUCCESS",
  EDIT_USERPOST_FAILURE: "EDIT_USERPOST_FAILURE",
  // DELETING USER POSTS
  DELETE_USERPOST_START: "DELETE_USERPOST_START",
  DELETE_USERPOST_SUCCESS: "DELETE_USERPOST_SUCCESS",
  DELETE_USERPOST_FAILURE: "DELETE_USERPOST_FAILURE",

  // GETTING POST COMMENTS
  FETCH_POSTCOMMENTS_START: "FETCH_POSTCOMMENTS_START",
  FETCH_POSTCOMMENTS_SUCCESS: "FETCH_POSTCOMMENTS_SUCCESS",
  FETCH_POSTCOMMENTS_FAILURE: "FETCH_POSTCOMMENTS_FAILURE",
  // ADDING USER COMMENT
  ADD_POSTCOMMENTS_START: "ADD_POSTCOMMENTS_START",
  ADD_POSTCOMMENTS_SUCCESS: "ADD_POSTCOMMENTS_SUCCESS",
  ADD_POSTCOMMENTS_FAILURE: "ADD_POSTCOMMENTS_FAILURE",
  // EDITING POST COMMENT
  EDIT_POSTCOMMENTS_START: "EDIT_POSTCOMMENTS_START",
  EDIT_POSTCOMMENTS_SUCCESS: "EDIT_POSTCOMMENTS_SUCCESS",
  EDIT_POSTCOMMENTS_FAILURE: "EDIT_POSTCOMMENTS_FAILURE",
  // DELETING POST COMMENT
  DELETE_POSTCOMMENTS_START: "DELETE_POSTCOMMENTS_START",
  DELETE_POSTCOMMENTS_SUCCESS: "DELETE_POSTCOMMENTS_SUCCESS",
  DELETE_POSTCOMMENTS_FAILURE: "DELETE_POSTCOMMENTS_FAILURE",

  // GETTING RESPONSE COMMENTS
  FETCH_RESPONSECOMMENTS_START: "FETCH_RESPONSECOMMENTS_START",
  FETCH_RESPONSECOMMENTS_SUCCESS: "FETCH_RESPONSECOMMENTS_SUCCESS",
  FETCH_RESPONSECOMMENTS_FAILURE: "FETCH_RESPONSECOMMENTS_FAILURE",
  // ADDING RESPONSE COMMENT
  ADD_RESPONSECOMMENTS_START: "ADD_RESPONSECOMMENTS_START",
  ADD_RESPONSECOMMENTS_SUCCESS: "ADD_RESPONSECOMMENTS_SUCCESS",
  ADD_RESPONSECOMMENTS_FAILURE: "ADD_RESPONSECOMMENTS_FAILURE",
  // EDITING RESPONSE COMMENT
  EDIT_RESPONSECOMMENTS_START: "EDIT_RESPONSECOMMENTS_START",
  EDIT_RESPONSECOMMENTS_SUCCESS: "EDIT_RESPONSECOMMENTS_SUCCESS",
  EDIT_RESPONSECOMMENTS_FAILURE: "EDIT_RESPONSECOMMENTS_FAILURE",
  // DELETING RESPONSE COMMENT
  DELETE_RESPONSECOMMENTS_START: "DELETE_RESPONSECOMMENTS_START",
  DELETE_RESPONSECOMMENTS_SUCCESS: "DELETE_RESPONSECOMMENTS_SUCCESS",
  DELETE_RESPONSECOMMENTS_FAILURE: "DELETE_RESPONSECOMMENTS_FAILURE",
};

export default constants;
