import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getPostComments } from "../../redux/actions";
import CommentCard from "./CommentCard";

const CommentList = (props) => {
  const { id } = useParams();
  const { getPostComments, commentSubmit } = props;
  const [threadComments, setThreadComments] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [replySubmit, setReplySubmit] = useState(false);

  useEffect(() => {
    getPostComments(id);
  }, [setIsLoadingComments, id, getPostComments, commentSubmit, replySubmit]);

  useEffect(() => {
    setThreadComments(
      props.postComments.filter((comment) => comment.parent_id !== null)
    );
  }, [props.postComments, replySubmit]);

  return (
    <div className="comment-list">
      <h3 className="comment-list-header">COMMENTS</h3>
      {props.postComments
        .filter((comment) => comment.parent_id === null)
        .map((comment) => (
          <CommentCard
            key={comment.id}
            id={comment.id}
            post_id={comment.post_id}
            comment_body={comment.comment_body}
            created_at={comment.created_at}
            user_id={comment.user_id}
            setIsLoadingComments={setIsLoadingComments}
            isLoadingComments={isLoadingComments}
            threadComments={threadComments}
            replySubmit={replySubmit}
            setReplySubmit={setReplySubmit}
          />
        ))}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    postComments: serverReducer.postComments,
    isFetchingPostComments: serverReducer.isFetchingPostComments,
  };
};

export default connect(mapStateToProps, { getPostComments })(CommentList);
