import React from "react";
import { useHistory } from "react-router-dom";
import NotificationCard from "./NotificationCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const NotificationsList = (props) => {
  const history = useHistory();
  // const [likes, setLikes] = useState()
  // const [comments, setComments] = useState([])
  // const [newTopics, setNewTopics] = useState()

  const userPosts = props.userPosts;
  const likes = props.allLikes;
  const topicNotifications = props.topics;

  const likeNotifications = () => {
    const userPostsDict = {};
    const likeNotificationsDict = {};
    for (let i = 0; i < userPosts.length; i++) {
      const post_id = userPosts[i].id;
      const post_body = userPosts[i].post_body;
      const topic_id = userPosts[i].topic_id;
      const created_at = userPosts[i].created_at;
      const user_id = userPosts[i].user_id;
      userPostsDict[userPosts[i].id] = {
        post_id,
        post_body,
        topic_id,
        created_at,
        user_id,
      };
    }
    for (let j = 0; j < likes.length; j++) {
      if (likes[j].post_id in userPostsDict) {
        // const like = userPostsDict[likes[j].post_id]
        const user_name = likes[j].user_name;
        const created_at = likes[j].created_at;
        const post_body = userPostsDict[likes[j].post_id].post_body;
        const post_id = userPostsDict[likes[j].post_id].post_id;
        const topic_id = userPostsDict[likes[j].post_id].topic_id;
        likeNotificationsDict[likes[j].id] = {
          user_name,
          created_at,
          post_body,
          post_id,
          topic_id,
        };
      }
    }
    const sortedLikes = Object.entries(likeNotificationsDict).sort((a, b) =>
      a[1].created_at < b[1].created_at ? 1 : -1
    );
    return sortedLikes.map((like) => like[1]);
  };

  const compiledNotfications = likeNotifications().concat(topicNotifications);

  return (
    <div className="notifications">
      <div className="single-post-header-container">
        <button className="back-button-style" onClick={history.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <h3 className="single-post-header">NOTIFICATIONS</h3>
      </div>
      {compiledNotfications.map((notification) => {
        return (
          <div key={`${notification.id}${notification.topic_id}`}>
            <NotificationCard
              postId={notification.post_id}
              post_body={notification.post_body}
              topicName={notification.topic_name}
              createdAt={notification.created_at}
              topicId={notification.topic_id}
              userId={notification.user_id}
              categoryId={notification.category_id}
              user_name={notification.user_name}
            />
          </div>
        );
      })}
    </div>
  );
};

export default NotificationsList;
