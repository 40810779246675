import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getUserInfo,
  getUserPosts,
  getPostComments,
} from "../../redux/actions";
import Moment from "moment";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
//Icons
import CheckBox from "../../assets/icons/CheckBox";
import XBox from "../../assets/icons/XBox";
import UpVote from "../../assets/icons/UpVote";
import DownVote from "../../assets/icons/DownVote";
import CommentIcon from "../../assets/icons/CommentIcon";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfilePostCards = (props) => {
  const { user_name } = useParams();
  const userId = localStorage.getItem("user_id");
  const postId = props.id;
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [topicName, setTopicName] = useState();
  const [userImage, setUserImage] = useState("https://via.placeholder.com/150");
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const { user, topic_id, user_id } = props;

  // Get likes
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadLikes = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/posts/${postId}/likes`, { cancelToken: source.token })
          .then((res) => {
            setLikes(res.data.length);
            const findLike = res.data.filter(
              (like) => like.user_name === user.user_name
            );
            if (findLike.length) {
              setIsLiked(true);
            } else {
              setIsLiked(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadLikes();
    return () => {
      source.cancel();
    };
  }, [likes, dislikes, user_name, postId, user.user_name]);

  // Get dislikes
  useEffect(() => {
    let source = Axios.CancelToken.source();
    try {
      axiosWithAuth()
        .get(`/api/posts/${postId}/dislikes`, { cancelToken: source.token })
        .then((res) => {
          setDislikes(res.data.length);
          const findDislike = res.data.filter(
            (dislike) => dislike.user_name === user.user_name
          );
          if (findDislike.length) {
            setIsDisliked(true);
          } else {
            setIsDisliked(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
    return () => {
      source.cancel();
    };
  }, [likes, dislikes, user_name, postId, user.user_name]);

  // like post
  const likePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${postId}/likes`, {
        user_name: props.user.user_name,
      })
      .then((res) => {
        setLikes(likes + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // dislike post
  const disLikePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${postId}/dislikes`, {
        user_name: props.user.user_name,
      })
      .then((res) => {
        setDislikes(dislikes - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function Vote() {
    if (!props.vote) {
      return (
        <div className="vote-status">
          <XBox />
        </div>
      );
    } else {
      return (
        <div className="vote-status">
          <CheckBox />
        </div>
      );
    }
  }
  function UpVotePercentage() {
    const totalVotes = likes + dislikes;
    const voteRatio = Math.ceil((likes / totalVotes) * 100);
    if (!likes) {
      return <span>0%</span>;
    } else {
      return <span>{voteRatio}%</span>;
    }
  }
  function DownVotePercentage() {
    const totalVotes = likes + dislikes;
    const voteRatio = Math.floor((dislikes / totalVotes) * 100);
    if (!dislikes) {
      return <span>0%</span>;
    } else {
      return <span>{voteRatio}%</span>;
    }
  }
  // get topic name
  useEffect(() => {
    let source = Axios.CancelToken.source();
    try {
      axiosWithAuth()
        .get(`/api/topics/${topic_id}`, { cancelToken: source.token })
        .then((res) => {
          setTopicName(res.data.topic_name);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
    return () => {
      source.cancel();
    };
  }, [setTopicName, topic_id]);

  // get user image
  useEffect(() => {
    let source = Axios.CancelToken.source();
    try {
      axiosWithAuth()
        .get(`/api/users/${user_id}`, { cancelToken: source.token })
        .then((res) => {
          setUserImage(res.data.photo_url);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
    return () => {
      source.cancel();
    };
  }, [setUserImage, user_id]);

  function renderUserImage() {
    if (!userImage) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img className="post-user-image" src={userImage} alt="user profile" />
      );
    }
  }

  function checkIfUserShowOptions() {
    if (props.user_id === Number(userId)) {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu size="sm">
            <button
              style={{
                background: "none",
                border: "none",
                outline: "none",
                display: "flex",
              }}
              onClick={deletePost}
            >
              delete
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <button
              style={{
                background: "none",
                border: "none",
                outline: "none",
                display: "flex",
              }}
              to={"#/action-1"}
            >
              report
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }
  function deletePost() {
    axiosWithAuth()
      .delete(`/api/posts/${props.id}`)
      .then((res) => {
        console.log(res);
        !props.toggleDelete
          ? props.setToggleDelete(true)
          : props.setToggleDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function renderUpVoteButton() {
    if (isLiked) {
      return (
        <button onClick={null} className="liked-button-style">
          <UpVote />
        </button>
      );
    } else {
      return (
        <button onClick={likePost} className="like-button-style">
          <UpVote />
        </button>
      );
    }
  }

  function renderDownVoteButton() {
    if (isDisliked) {
      return (
        <button onClick={null} className="disliked-button-style">
          <DownVote />
        </button>
      );
    } else {
      return (
        <button onClick={disLikePost} className="dislike-button-style">
          <DownVote />
        </button>
      );
    }
  }

  return (
    <div className="post-card" key={props.id}>
      {Vote()}
      <h3>{topicName}</h3>
      <Link
        style={{ textDecoration: "none" }}
        key={props.id}
        to={`/home/post/${props.id}`}
      >
        <div className="post-interior-container">
          {renderUserImage()}
          <p className="post-body">
            <span style={{ fontWeight: "bold" }}>{props.user_name}</span>
            <br />
            {props.post_body}
          </p>
        </div>
      </Link>
      <p className="post-user-log">
        Posted {Moment(props.created_at).calendar()}
      </p>
      <div className="post-icon-container">
        {UpVotePercentage()}
        {renderUpVoteButton()}
        {DownVotePercentage()}
        {renderDownVoteButton()}
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view discussion</Tooltip>}
        >
          <Link to={`/home/post/${props.id}`} className="button-style">
            <CommentIcon />
          </Link>
        </OverlayTrigger>
        {checkIfUserShowOptions()}
      </div>
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    userId: serverReducer.userId,
    userPosts: serverReducer.userPosts,
    isFetchingUserPosts: serverReducer.isFetchingUserPosts,
    postComments: serverReducer.postComments,
    isFetchingPostComments: serverReducer.isFetchingPostComments,
  };
};

export default connect(mapStateToProps, {
  getUserInfo,
  getUserPosts,
  getPostComments,
})(ProfilePostCards);
