import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getUserPosts } from "../../redux/actions";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Moment from "moment";
import CategoryPostForm from "./CategoryPostForm";
import { Card } from "react-bootstrap";
import CommentIcon from "../../assets/icons/CommentIcon";
import OppositeOpinions from "./OppositeOpinions";

const Category = (props) => {
  const userId = localStorage.getItem("user_id");
  const [postSuccess, setPostSuccess] = useState(false);
  const [topics, setTopics] = useState([]);
  const { id, category } = useParams();
  const { getUserPosts } = props;

  useEffect(() => {
    getUserPosts(userId);
  }, [postSuccess, userId, getUserPosts]);

  useEffect(() => {
    const fetchTopics = async () => {
      await axiosWithAuth()
        .get(`/api/categories/${id}`)
        .then((res) => {
          setTopics(res.data.topics.sort((a, b) => (a.id < b.id ? 1 : -1)));
        })
        .catch((err) => console.log);
    };
    fetchTopics();
  }, [id]);

  function checkTopicForUserResponse() {
    const allUserPosts = props.userPosts;
    return (
      <>
        {topics.map((topic) => {
          const postMatchesTopic = allUserPosts.filter((post) =>
            post.topic_id === topic.id ? post : null
          );
          if (!postMatchesTopic.length) {
            return (
              <Card
                posts={props.posts}
                key={topic.id}
                className="topic-card-container"
              >
                <div className="topic-title">{topic.topic_name}</div>
                <div className="topic-body-container">
                  <CategoryPostForm
                    setPostSuccess={setPostSuccess}
                    postSuccess={postSuccess}
                    topics={topics}
                    topicId={topic.id}
                  />
                  <div className="topic-post-subtext">
                    <p>Posted {Moment(topic.created_at).calendar()}</p>
                    <button>
                      <CommentIcon />
                    </button>
                  </div>
                </div>
                <div className="topic-post-container">
                  <img src="" alt="" />
                </div>
              </Card>
            );
          } else {
            return (
              <Card
                posts={props.posts}
                key={topic.id}
                className="topic-card-container"
              >
                <div className="topic-title">{topic.topic_name}</div>
                <div className="topic-body-container-complete">
                  <div className="topic-body-success">
                    <h2>Thank you for your submission!</h2>
                  </div>
                  <div className="topic-post-subtext">
                    <p
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      Posted {Moment(postMatchesTopic[0].created_at).calendar()}
                    </p>
                  </div>
                </div>
                <div className="topic-post-container">
                  <img src="" alt="" />
                  <OppositeOpinions
                    id={postMatchesTopic[0].id}
                    topic_id={postMatchesTopic[0].topic_id}
                    vote={postMatchesTopic[0].vote}
                    post_body={postMatchesTopic[0].post_body}
                    userPosts={props.userPosts}
                    categoryId={id}
                    allLikes={props.allLikes}
                    user={props.user}
                  />
                </div>
              </Card>
            );
          }
        })}
      </>
    );
  }

  return (
    <div className="category-container">
      <div className="category-title-container">
        <h1 className="category-title">{category}</h1>
      </div>
      {checkTopicForUserResponse()}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    userPosts: serverReducer.userPosts,
    isFetchingUserPosts: serverReducer.isFetchingUserPosts,
  };
};

export default connect(mapStateToProps, { getUserPosts })(Category);
