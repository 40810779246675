import React from "react";
import { Route, Switch } from "react-router-dom";
//components
import Home from "./Home";
import PrivateRoute from "./components/login/PrivateRoute";
import Welcome from "./Welcome";
import WelcomeNav from "./components/navigation/WelcomeNav";
import HowItWorks from "./HowItWorks";
// import About from './About';
//style libraries
import "bootstrap/dist/css/bootstrap.min.css";
// import './App.css';
import "./styles/styles.css";

const App = () => {
  return (
    <div className="app">
      <Switch>
        <Route exact path="/">
          <WelcomeNav />
          <Welcome />
        </Route>
        <Route exact path="/howitworks">
          <WelcomeNav />
          <HowItWorks />
        </Route>
        <Route exact path="/about">
          <WelcomeNav />
          {/* <About /> */}
        </Route>
        <PrivateRoute exact path="/home/profile/:user_name" component={Home} />
        <PrivateRoute
          exact
          path="/home/profile/:user_name/liked"
          component={Home}
        />
        <PrivateRoute
          exact
          path="/home/profile/:user_name/polls"
          component={Home}
        />
        <PrivateRoute
          exact
          path="/home/profile/:user_name/sources"
          component={Home}
        />
        <PrivateRoute exact path="/home/:nav" component={Home} />
        <PrivateRoute exact path="/home/category/:id" component={Home} />
        <PrivateRoute exact path="/home/post/:id" component={Home} />
        <PrivateRoute exact path="/home/poll/:id" component={Home} />
        <PrivateRoute exact path="/home/status/:id" component={Home} />
        <Route path="/" render={() => <div>Page does not exist...</div>} />
      </Switch>
    </div>
  );
};

export default App;
