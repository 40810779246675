import constants from "../constants";

const initialState = {
  // find user

  // user info
  user: [],
  userId: "",
  error: "",
  isFetchingUserInfo: false,
  isAddingUserInfo: false,
  isEditingUserInfo: false,
  // follow users
  isFollowingUser: false,
  isUnfollowingUser: false,
  userFollowing: [],
  isFetchingUserFollowing: false,
  // user photo
  photo: [],
  isAddingUserPhoto: false,
  isDeletingUserPhoto: false,
  // categories
  categories: [],
  isFetchingCategories: false,
  // topic posts
  topicPosts: [],
  isFetchingTopicPosts: false,
  isAddingTopicPosts: false,
  // trending posts
  trendingPosts: [],
  isFetchingTrendingPosts: false,
  // single post
  post: [],
  isFetchingSinglePost: false,
  // single status
  status: [],
  isFetchingSingleStatus: false,
  // user posts
  userPosts: [],
  isFetchingUserPosts: false,
  isAddingUserPosts: false,
  isEditingUserPost: false,
  isDeletingUserPost: false,
  // profile posts
  profilePosts: [],
  isFetchingProfilePosts: false,
  // profile statuses
  profileStatuses: [],
  isFetchingProfileStatuses: false,
  // profile sources
  profileSources: [],
  isFetchingProfileSources: false,
  // profile polls
  profilePolls: [],
  isFetchingProfilePolls: false,
  singlePoll: [],
  isFetchingSinglePoll: false,
  // post comments
  postComments: [],
  isFetchingPostComments: false,
  isAddingPostComments: false,
  isEditingPostComments: false,
  isDeletingPostComments: false,

  // response comments
  responseComments: [],
  isFetchingResponseComments: false,
  isAddingResponseComments: false,
  isEditingResponseComments: false,
  isDeletingResponseComments: false,

  // opposite posts
  oppositePosts: [],
  isFetchingOppositePosts: false,
};

export const serverReducer = (state = initialState, action) => {
  switch (action.type) {
    // log in
    case constants.STORE_LOGIN:
      return {
        ...state,
        userId: action.payload,
      };
    // get user info
    case constants.FETCH_USERINFO_START:
      return {
        ...state,
        error: "",
        isFetchingUserInfo: true,
      };
    case constants.FETCH_USERINFO_SUCCESS:
      return {
        ...state,
        isFetchingUserInfo: false,
        user: action.payload,
      };
    case constants.FETCH_USERINFO_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingUserInfo: false,
      };
    // post user info
    case constants.ADD_USERINFO_START:
      return {
        ...state,
        isAddingUserInfo: true,
        error: "",
      };
    case constants.ADD_USERINFO_SUCCESS:
      return {
        ...state,
        user: [...state.user, action.payload],
        error: "",
        isAddingUserInfo: false,
      };
    case constants.ADD_USERINFO_FAILURE:
      return {
        ...state,
        isAddingUserInfo: false,
        error: action.payload,
      };
    // edit user info
    case constants.EDIT_USERINFO_START:
      return {
        ...state,
        isEditingUserInfo: true,
        error: "",
      };
    case constants.EDIT_USERINFO_SUCCESS:
      return {
        ...state,
        user: [...state.user, action.payload],
        error: "",
        isEditingUserInfo: false,
      };
    case constants.EDIT_USERINFO_FAILURE:
      return {
        ...state,
        isEditingUserInfo: false,
        error: action.payload,
      };
    // follow user
    case constants.FOLLOW_USER_START:
      return {
        ...state,
        isFollowingUser: true,
        error: "",
      };
    case constants.FOLLOW_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          following: [...state.user.following, action.payload],
        },
        error: "",
        isFollowingUser: false,
      };
    case constants.FOLLOW_USER_FAILURE:
      return {
        ...state,
        isFollowingUser: false,
        error: action.payload,
      };
    // unfollow user
    case constants.UNFOLLOW_USER_START:
      return {
        ...state,
        isUnfollowingUser: true,
        error: "",
      };
    case constants.UNFOLLOW_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          following: state.user.following.filter(
            (item) => item !== action.payload
          ),
        },
        error: "",
        isFollowingUser: false,
      };
    case constants.UNFOLLOW_USER_FAILURE:
      return {
        ...state,
        isUnfollowingUser: false,
        error: action.payload,
      };
    // get user following
    case constants.FETCH_USERFOLLOWING_START:
      return {
        ...state,
        error: "",
        isFetchingUserFollowing: true,
      };
    case constants.FETCH_USERFOLLOWING_SUCCESS:
      return {
        ...state,
        isFetchingUserFollowing: false,
        userFollowing: [...action.payload],
      };
    case constants.FETCH_USERFOLLOWING_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingUserFollowing: false,
      };
    // user photo
    case constants.ADD_USERPHOTO_START:
      return {
        ...state,
        isAddingUserPhoto: true,
        error: "",
      };
    case constants.ADD_USERPHOTO_SUCCESS:
      return {
        ...state,
        photo: [...state.photo, action.payload],
        error: "",
        isAddingUserPhoto: false,
      };
    case constants.ADD_USERPHOTO_FAILURE:
      return {
        ...state,
        isAddingUserPhoto: false,
        error: action.payload,
      };
    case constants.DELETE_USERPHOTO_START:
      return {
        ...state,
        error: "",
        isDeletingUserPhoto: true,
      };
    case constants.DELETE_USERPHOTO_SUCCESS:
      return {
        ...state,
        error: "",
        isDeletingUserPhoto: false,
        photo: [...state.photo, action.payload],
      };
    case constants.DELETE_USERPHOTO_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDeletingUserPhoto: false,
      };
    // categories list
    case constants.FETCH_CATEGORIES_START:
      return {
        ...state,
        error: "",
        isFetchingCategories: true,
      };
    case constants.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingCategories: false,
        categories: [...action.payload],
      };
    case constants.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingCategories: false,
      };
    // topic posts
    case constants.FETCH_TOPICPOSTS_START:
      return {
        ...state,
        error: "",
        isFetchingTopicPosts: true,
      };
    case constants.FETCH_TOPICPOSTS_SUCCESS:
      return {
        ...state,
        isFetchingTopicPosts: false,
        topicPosts: [...action.payload],
      };
    case constants.FETCH_TOPICPOSTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingTopicPosts: false,
      };
    case constants.ADD_TOPICPOST_START:
      return {
        ...state,
        isAddingTopicPosts: true,
        error: "",
      };
    case constants.ADD_TOPICPOST_SUCCESS:
      return {
        ...state,
        topicPosts: [...state.topicPosts, action.payload],
        error: "",
        isAddingTopicPosts: false,
      };
    case constants.ADD_TOPICPOST_FAILURE:
      return {
        ...state,
        isAddingTopicPosts: false,
        error: action.payload,
      };
    // trending posts
    case constants.FETCH_TRENDINGPOSTS_START:
      return {
        ...state,
        error: "",
        isFetchingTrendingPosts: true,
      };
    case constants.FETCH_TRENDINGPOSTS_SUCCESS:
      return {
        ...state,
        isFetchingTrendingPosts: false,
        trendingPosts: [...action.payload],
      };
    case constants.FETCH_TRENDINGPOSTS_FAILURE:
      return {
        ...state,
        isFetchingTrendingPosts: false,
        error: action.payload,
      };
    // opposite posts
    case constants.FETCH_OPPOSITEOPINIONS_START:
      return {
        ...state,
        error: "",
        isFetchingOppositePosts: true,
      };
    case constants.FETCH_OPPOSITEOPINIONS_SUCCESS:
      return {
        ...state,
        isFetchingOppositePosts: false,
        oppositePosts: [...action.payload],
      };
    case constants.FETCH_OPPOSITEOPINIONS_FAILURE:
      return {
        ...state,
        isFetchingOppositePosts: false,
        error: action.payload,
      };
    // single post
    case constants.FETCH_SINGLEPOST_START:
      return {
        ...state,
        error: "",
        isFetchingSinglePost: true,
      };
    case constants.FETCH_SINGLEPOST_SUCCESS:
      return {
        ...state,
        isFetchingSinglePost: false,
        post: action.payload,
      };
    case constants.FETCH_SINGLEPOST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingSinglePost: false,
      };

    // single status
    case constants.FETCH_SINGLESTATUS_START:
      return {
        ...state,
        error: "",
        isFetchingSingleStatus: true,
      };
    case constants.FETCH_SINGLESTATUS_SUCCESS:
      return {
        ...state,
        isFetchingSingleStatus: false,
        status: action.payload,
      };
    case constants.FETCH_SINGLESTATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingSingleStatus: false,
      };

    // profile posts
    case constants.FETCH_PROFILEPOSTS_START:
      return {
        ...state,
        error: "",
        isFetchingProfilePosts: true,
      };
    case constants.FETCH_PROFILEPOSTS_SUCCESS:
      return {
        ...state,
        isFetchingProfilePosts: false,
        profilePosts: [...action.payload],
      };
    case constants.FETCH_PROFILEPOSTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingProfilePosts: false,
      };
    // profile statuses
    case constants.FETCH_PROFILESTATUSES_START:
      return {
        ...state,
        error: "",
        isFetchingProfileStatuses: true,
      };
    case constants.FETCH_PROFILESTATUSES_SUCCESS:
      return {
        ...state,
        isFetchingProfileStatuses: false,
        profileStatuses: [...action.payload],
      };
    case constants.FETCH_PROFILESTATUSES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingProfileStatuses: false,
      };
    // profile sources
    case constants.FETCH_PROFILESOURCES_START:
      return {
        ...state,
        error: "",
        isFetchingProfileSources: true,
      };
    case constants.FETCH_PROFILESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingProfileSources: false,
        profileSources: [...action.payload],
      };
    case constants.FETCH_PROFILESOURCES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingProfileSources: false,
      };
    // profile polls
    case constants.FETCH_PROFILEPOLLS_START:
      return {
        ...state,
        error: "",
        isFetchingProfilePolls: true,
      };
    case constants.FETCH_PROFILEPOLLS_SUCCESS:
      return {
        ...state,
        isFetchingProfilePolls: false,
        profilePolls: [...action.payload],
      };
    case constants.FETCH_PROFILEPOLLS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingProfilePolls: false,
      };
    // single polls
    case constants.FETCH_SINGLEPOLL_START:
      return {
        ...state,
        error: "",
        isFetchingSinglePoll: true,
      };
    case constants.FETCH_SINGLEPOLL_SUCCESS:
      return {
        ...state,
        isFetchingSinglePoll: false,
        singlePoll: { ...action.payload },
      };
    case constants.FETCH_SINGLEPOLL_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingSinglePoll: false,
      };

    // user posts
    case constants.FETCH_USERPOSTS_START:
      return {
        ...state,
        error: "",
        isFetchingUserPosts: true,
      };
    case constants.FETCH_USERPOSTS_SUCCESS:
      return {
        ...state,
        isFetchingUserPosts: false,
        userPosts: [...action.payload],
      };
    case constants.FETCH_USERPOSTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingUserPosts: false,
      };
    case constants.ADD_USERPOST_START:
      return {
        ...state,
        isAddingUserPosts: true,
        error: "",
      };
    case constants.ADD_USERPOST_SUCCESS:
      return {
        ...state,
        userPosts: [...state.userPosts, action.payload],
        error: "",
        isAddingUserPosts: false,
      };
    case constants.ADD_USERPOST_FAILURE:
      return {
        ...state,
        isAddingUserPosts: false,
        error: action.payload,
      };
    case constants.DELETE_USERPOST_START:
      return {
        ...state,
        error: "",
        isDeletingUserPost: true,
      };
    case constants.DELETE_USERPOST_SUCCESS:
      return {
        ...state,
        error: "",
        isDeletingUserPost: false,
        userPosts: [...state.userPosts, action.payload],
      };
    case constants.DELETE_USERPOST_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDeletingUserPost: false,
      };
    case constants.EDIT_USERPOST_START:
      return {
        ...state,
        isEditingUserPost: true,
        error: "",
      };
    case constants.EDIT_USERPOST_SUCCESS:
      return {
        ...state,
        isEditingUserPost: false,
        error: "",
        userPosts: [...state.userPosts, action.payload],
      };
    case constants.EDIT_USERPOST_FAILURE:
      return {
        ...state,
        isEditingUserPost: false,
        error: action.payload,
      };
    // post comments
    case constants.FETCH_POSTCOMMENTS_START:
      return {
        ...state,
        error: "",
        isFetchingPostComments: true,
      };
    case constants.FETCH_POSTCOMMENTS_SUCCESS:
      return {
        ...state,
        isFetchingPostComments: false,
        postComments: [...action.payload],
      };
    case constants.FETCH_POSTCOMMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingPostComments: false,
      };
    case constants.ADD_POSTCOMMENT_START:
      return {
        ...state,
        isAddingPostComments: true,
        error: "",
      };
    case constants.ADD_POSTCOMMENT_SUCCESS:
      return {
        ...state,
        postComments: [...state.postComments, action.payload],
        error: "",
        isAddingPostComments: false,
      };
    case constants.ADD_POSTCOMMENT_FAILURE:
      return {
        ...state,
        isAddingPostComments: false,
        error: action.payload,
      };
    case constants.DELETE_POSTCOMMENT_START:
      return {
        ...state,
        error: "",
        isDeletingPostComments: true,
      };
    case constants.DELETE_POSTCOMMENT_SUCCESS:
      return {
        ...state,
        error: "",
        isDeletingPostComments: false,
        postComments: [...state.postComments, action.payload],
      };
    case constants.DELETE_POSTCOMMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDeletingPostComments: false,
      };
    case constants.UPDATE_POSTCOMMENT_START:
      return {
        ...state,
        isEditingPostComments: true,
        error: "",
      };
    case constants.UPDATE_POSTCOMMENT_SUCCESS:
      return {
        ...state,
        isEditingPostComments: false,
        error: "",
        postComments: [...state.postComments, action.payload],
      };
    case constants.UPDATE_POSTCOMMENT_FAILURE:
      return {
        ...state,
        isEditingPostComments: false,
        error: action.payload,
      };
    // response comments
    case constants.FETCH_RESPONSECOMMENTS_START:
      return {
        ...state,
        error: "",
        isFetchingResponseComments: true,
      };
    case constants.FETCH_RESPONSECOMMENTS_SUCCESS:
      return {
        ...state,
        isFetchingResponseComments: false,
        responseComments: [...action.payload],
      };
    case constants.FETCH_RESPONSECOMMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetchingResponseComments: false,
      };
    case constants.ADD_RESPONSECOMMENT_START:
      return {
        ...state,
        isAddingResponseComments: true,
        error: "",
      };
    case constants.ADD_RESPONSECOMMENT_SUCCESS:
      return {
        ...state,
        responseComments: [...state.responseComments, action.payload],
        error: "",
        isAddingResponseComments: false,
      };
    case constants.ADD_RESPONSECOMMENT_FAILURE:
      return {
        ...state,
        isAddingResponseComments: false,
        error: action.payload,
      };
    case constants.DELETE_RESPONSECOMMENT_START:
      return {
        ...state,
        error: "",
        isDeletingResponseComments: true,
      };
    case constants.DELETE_RESPONSECOMMENT_SUCCESS:
      return {
        ...state,
        error: "",
        isDeletingResponseComments: false,
        responseComments: [...state.responseComments, action.payload],
      };
    case constants.DELETE_RESPONSECOMMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isDeletingResponseComments: false,
      };
    case constants.UPDATE_RESPONSECOMMENT_START:
      return {
        ...state,
        isEditingResponseComments: true,
        error: "",
      };
    case constants.UPDATE_RESPONSECOMMENT_SUCCESS:
      return {
        ...state,
        isEditingResponseComments: false,
        error: "",
        responseComments: [...state.responseComments, action.payload],
      };
    case constants.UPDATE_RESPONSECOMMENT_FAILURE:
      return {
        ...state,
        isEditingResponseComments: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
