import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UpVote from "../../assets/icons/UpVote";
import DownVote from "../../assets/icons/DownVote";
import CommentIcon from "../../assets/icons/CommentIcon";
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LikesCardInfo = (props) => {
  const [postUser, setPostUser] = useState({
    user_name: "",
    photo_url: "",
  });
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const userId = localStorage.getItem("user_id");
  const { postId, postUserId, user_name } = props;

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/posts/${postId}/likes`)
      .then((res) => {
        setLikes(res.data.length);
      })
      .catch((err) => err);
  }, [likes, dislikes, postId]);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/posts/${postId}/dislikes`)
      .then((res) => {
        setDislikes(res.data.length);
      })
      .catch((err) => err);
  }, [likes, dislikes, postId]);

  useEffect(() => {
    axiosWithAuth()
      .get(`/api/users/${postUserId}`)
      .then((res) => {
        setPostUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId, postUserId]);

  // like post
  const likePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${postId}/likes`, {
        user_name: user_name,
      })
      .then((res) => {
        setLikes(likes + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // dislike post
  const disLikePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${postId}/dislikes`, {
        user_name: user_name,
      })
      .then((res) => {
        setDislikes(dislikes - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function UpVotePercentage() {
    const totalVotes = likes + dislikes;
    const voteRatio = Math.ceil((likes / totalVotes) * 100);
    if (!likes) {
      return <span>0%</span>;
    } else {
      return <span>{voteRatio}%</span>;
    }
  }

  function DownVotePercentage() {
    const totalVotes = props.likes + props.dislikes;
    const voteRatio = Math.floor((props.dislikes / totalVotes) * 100);
    if (!props.dislikes) {
      return <span>0%</span>;
    } else {
      return <span>{voteRatio}%</span>;
    }
  }

  function renderUserImage() {
    if (!postUser.photo_url) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="post-user-image"
          src={postUser.photo_url}
          alt="user profile"
        />
      );
    }
  }

  // function checkIfUserShowOptions() {
  //     if (props.postUserId !== userId) {
  //         return (
  //             <Dropdown>
  //                 <Dropdown.Toggle style={{background:'none', border:'none', outline:'none'}}>
  //                     <button to={"#/action-1"} className='post-options-button'>
  //                         <FontAwesomeIcon icon={faChevronDown} size='1x' />
  //                     </button>
  //                 </Dropdown.Toggle>
  //                 <Dropdown.Menu>
  //                     <button style={{background:'none', border:'none', outline:'none'}} to={"#/action-1"}>report </button>
  //                 </Dropdown.Menu>
  //             </Dropdown>
  //         )
  //     } else {
  //         return (
  //             <Dropdown>
  //                 <Dropdown.Toggle style={{background:'none', border:'none', outline:'none'}}>
  //                     <button to={"#/action-1"} className='post-options-button'>
  //                         <FontAwesomeIcon icon={faChevronDown} size='1x' />
  //                     </button>
  //                 </Dropdown.Toggle>
  //                 <Dropdown.Menu>
  //                     <button style={{background:'none', border:'none', outline:'none'}} to={"#/action-1"}>report </button>
  //                 </Dropdown.Menu>
  //             </Dropdown>
  //         )
  //     }
  // }

  function renderUpvoteButton() {
    if (props.postId) {
      return (
        <button onClick={null} className="like-button-style">
          <UpVote />
        </button>
      );
    } else {
      return (
        <button onClick={likePost} className="like-button-style">
          <UpVote />
        </button>
      );
    }
  }

  return (
    <div>
      <div className="likes-interior-container">
        {renderUserImage()}
        <div className="likes-body">
          <span style={{ fontWeight: "bold" }}>{postUser.user_name}</span>
          <p>{props.postBody}</p>
        </div>
      </div>
      <p className="post-user-log">
        Posted {Moment(props.createdAt).calendar()}
      </p>
      <div className="post-icon-container">
        {UpVotePercentage()}
        {renderUpvoteButton()}
        {DownVotePercentage()}
        <button onClick={disLikePost} className="dislike-button-style">
          <DownVote />
        </button>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view discussion</Tooltip>}
          to={`/home/post/${props.postId}`}
        >
          <div className="comment-button-style">
            <CommentIcon />
          </div>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default LikesCardInfo;
