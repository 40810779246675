import React, { useState } from "react";
import PollCard from "./PollCard";
import PollForm from "./PollForm";
import {
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const pollButton = {
  cursor: "pointer",
  display: "flex",
  width: "50%",
  justifyContent: "flex-end",
  marginRight: "5px",
};

const PollsList = (props) => {
  const [showPollForm, setShowPollForm] = useState(false);
  const user_name = props.user_name;
  const { homeUserId, profileUserId } = props;

  function checkUserShowPollForm() {
    if (homeUserId === profileUserId.toString()) {
      return (
        <>
          <PollForm
            pollSubmit={props.pollSubmit}
            setPollSubmit={props.setPollSubmit}
            homeUserId={props.homeUserId}
            show={showPollForm}
            onHide={() => setShowPollForm(false)}
            setShowPollForm={setShowPollForm}
            showPollForm={showPollForm}
          />
          <span style={pollButton} onClick={() => setShowPollForm(true)}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tool-tip-top`}>create poll</Tooltip>}
            >
              <FontAwesomeIcon icon={faChartBar} size="lg" />
            </OverlayTrigger>
          </span>
        </>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="likes-list-container">
      <div className="post-list-header">
        <h3>{user_name.toUpperCase()}'S POLLS</h3>
        {checkUserShowPollForm()}
        <DropdownButton
          title="All"
          variant="light"
          className="filter-drop-down"
        >
          <Dropdown.Item eventKey="1" active>
            All
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">Most recent</Dropdown.Item>
          <Dropdown.Item eventKey="3">Highest rated</Dropdown.Item>
        </DropdownButton>
      </div>
      {props.profilePolls.map((poll) => (
        <PollCard
          key={poll.id}
          user_name={user_name}
          pollId={poll.id}
          pollUserId={poll.user_id}
          pollTitle={poll.poll_title}
          pollBody={poll.poll_body}
          createdAt={poll.created_at}
          setToggleDelete={props.setToggleDelete}
        />
      ))}
    </div>
  );
};

export default PollsList;
