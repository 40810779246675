import React from "react";
import Moment from "moment";

const NotificationCard = (props) => {
  function notificationType() {
    if (props.topicName) {
      return (
        <div className="notification-card">
          <div className="notification-header">
            <h3>
              <span style={{ fontWeight: "bold", color: "#EB5757" }}>
                New topic!
              </span>
            </h3>
            <span style={{ color: "#333333" }}>3 mins ago</span>
          </div>
          <div className="notification-body">
            <p style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
              {props.topicName}
            </p>
          </div>
        </div>
      );
    } else if (props.postId) {
      return (
        <div className="notification-card">
          <div className="notification-header">
            <h3>
              <span style={{ fontWeight: "bold", color: "#EB5757" }}>
                {props.user_name}
              </span>{" "}
              liked your opinion:
            </h3>
            <span style={{ color: "#333333" }}>
              {Moment(props.createdAt).calendar()}
            </span>
          </div>
          <div className="notification-body">
            <p style={{ fontSize: "0.8rem" }}>{props.post_body}</p>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="notification-card-container">{notificationType()}</div>
  );
};

export default NotificationCard;
