import React, { useState, useEffect } from "react";
import { withFormik, Form, Field } from "formik";
import * as yup from "yup";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Spinner from "../spinners/spinner";

const Register = ({
  touched,
  setShowRegister,
  errors,
  status,
  isLoading,
  showError,
  errorMessage,
}) => {
  const [credentials, setCredentials] = useState([]);
  useEffect(() => {
    status && setCredentials((credentials) => [...credentials, status]);
  }, [status]);

  return (
    <div className="login-box">
      <Form className="login-form">
        <label
          style={{ fontWeight: "bold", color: "#334550", fontSize: "2rem" }}
        >
          Register
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Field
            className="login-field"
            type="text"
            placeholder="first name"
            name="firstname"
          />
          {touched.firstname && errors.firstname && (
            <p className="error">{errors.firstname}</p>
          )}
          <Field
            className="login-field"
            type="text"
            placeholder="last name"
            name="lastname"
          />
          {touched.lastname && errors.lastname && (
            <p className="error">{errors.lastname}</p>
          )}
          <Field
            className="login-field"
            type="email"
            placeholder="email"
            name="email"
          />
          {touched.email && errors.email && (
            <p className="error">{errors.email}</p>
          )}
          <Field
            className="login-field"
            type="text"
            placeholder="username"
            name="user_name"
          />
          {touched.user_name && errors.user_name && (
            <p className="error">{errors.user_name}</p>
          )}
          <Field
            className="login-field"
            type="password"
            placeholder="password"
            name="password"
          />
          {touched.password && errors.password && (
            <p className="error">{errors.password}</p>
          )}
          <Field
            className="login-field"
            type="password"
            placeholder="confirm password"
            name="confirmPassword"
          />
          {touched.confirmPassword && errors.confirmPassword && (
            <p className="error">{errors.confirmPassword}</p>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <button type="submit" className="login-button" disabled={!!isLoading}>
            {!!isLoading ? (
              <Spinner color="#fff" width="14" height="14" />
            ) : (
              "Submit"
            )}
          </button>
          {!!showError && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#EB5757",
                fontSize: "10px",
                fontWeigh: "bold",
              }}
            >
              {errorMessage}
            </p>
          )}
          <button
            style={{ background: "none", border: "none", color: "#334550" }}
            onClick={() => setShowRegister(false)}
            disabled={!!isLoading}
          >
            Already a member? Click to login!
          </button>
        </div>
      </Form>
    </div>
  );
};

const FormikRegister = withFormik({
  mapPropsToValues({
    firstname,
    lastname,
    email,
    user_name,
    password,
    confirmPassword,
  }) {
    return {
      firstname: firstname || "",
      lastname: lastname || "",
      email: email || "",
      user_name: user_name || "",
      password: password || "",
      confirmPassword: confirmPassword || "",
    };
  },
  validationSchema: yup.object().shape({
    firstname: yup
      .string()
      .min(2, "*first name must be at least 2 characters long")
      .max(16, "*first name must be less than 16 characters long")
      .required("*please enter a first name"),
    lastname: yup
      .string()
      .min(2, "*last name must be at least 2 characters long")
      .max(16, "*last name must be less than 16 characters long")
      .required("*please enter a last name"),
    email: yup
      .string()
      .email("*must enter a valid email address")
      .required("*please enter your email address"),
    user_name: yup
      .string()
      .min(6, "*user name must be at least 6 characters long")
      .max(16, "*user name must be less than 16 characters long")
      .required("please enter a user name")
      .matches(/^\S*$/, "user name cannot have any blank spaces"),
    password: yup
      .string()
      .min(8, "passwords must be at least 8 characters long")
      .max(16, "passwords must be less than 16 characters long")
      // .matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$")
      .required("password is required"),
    confirmPassword: yup
      .string()
      .required("please retype password")
      .test("passwords-match", "passwords do not match", function (value) {
        return this.parent.password === value;
      }),
  }),

  handleSubmit(values, { resetForm, setStatus, setErrors, props }) {
    props.setIsLoading(true);
    props.setShowError(false);
    if (values.email === "eian@test.com") {
      setErrors({ email: "email already assigned to an account!" });
    } else {
      axiosWithAuth()
        .post("/api/auth/register", {
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          user_name: values.user_name,
          password: values.password,
        })
        .then((res) => {
          props.setIsLoading(false);
          axiosWithAuth()
            .post("api/auth/login", {
              email: values.email,
              password: values.password,
            })
            .then((res) => {
              setStatus(res.data);
              localStorage.setItem("user_id", res.data.id);
              localStorage.setItem("token", res.data.token);
              resetForm();
              props.history.push(`/home/recommended`);
            })
            .catch((err) => {
              console.log(err);
              props.setIsLoading(false);
              props.setShowError(true);
              props.setErrorMessage("* unable to register user");
            });
        })
        .catch((err) => console.log(err));
    }
  },
})(Register);

export default FormikRegister;
