import React from "react";

function SearchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21">
      <g strokeWidth="2" stroke="#6c6c6c" fill="none">
        <path d="M11.29 11.71l-4-4" />
        <circle cx="5" cy="5" r="4" />
      </g>
    </svg>
  );
}

export default SearchIcon;
