import React, { useState } from "react";
// Components
import CommentThreadCard from "./CommentThreadCard";
// Icons
import Spinner from "../../spinners/spinner";

const CommentThread = (props) => {
  const { threadComments, commentId } = props;
  const [isLoadingComments, setIsLoadingComments] = useState(false);

  function filterThread() {
    const filteredComments = threadComments.filter(
      (comment) => comment.parent_id === commentId
    );
    return filteredComments;
  }

  return !threadComments ? (
    <span style={{ display: "flex", justifyContent: "center" }}>
      <Spinner width="38" height="38" color="#FF3781" />
    </span>
  ) : (
    <div className="comment-thread-container">
      {filterThread().map((comment) => {
        return (
          <CommentThreadCard
            key={comment.id}
            id={comment.id}
            post_id={comment.post_id}
            comment_body={comment.comment_body}
            created_at={comment.created_at}
            user_id={comment.user_id}
            setIsLoadingComments={setIsLoadingComments}
            isLoadingComments={isLoadingComments}
            threadComments={threadComments}
            replySubmit={props.replySubmit}
            setReplySubmit={props.setReplySubmit}
            subThreadLength={filterThread().length}
          />
        );
      })}
    </div>
  );
};

export default CommentThread;
