import React from "react";
import { Modal, Button } from "react-bootstrap";

const modalStyle = {
  color: "#333333",
  fontWeight: "bold",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const modalBodyStyle = {
  color: "#333333",
  fontWeight: "normal",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const modalButton = {
  marginTop: "2%",
  padding: "1% 3% 1% 3%",
  borderRadius: "30px",
  background: "#F2857B",
  color: "white",
  fontWeight: "bold",
  borderStyle: "none",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
};

export default function PrivacyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      area-labelledby="contained-modal-title-vcenter"
      centered
      style={modalStyle}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <p>
          “Scope of this Agreement The Software-Product accompanying this
          Agreement as a pre-release copy and all affiliated materials,
          including documentation and information (collectively the “Product”),
          is copyrighted. Scope of this agreement is the licensing (not selling)
          of the “Product” to You, as the ‘user’ (either an individual or an
          entity). PARAGON reserves all rights not expressly granted.”
        </p>
        <p>
          “Please read and agree to the following terms and conditions, if you
          wish to be eligible to participate in the Closed Beta Testing.
          However, we do not guarantee that you will be selected to participate
          in the Beta Testing. All applicants are required to have a Square Enix
          ID prior to submitting their application. BY SELECTING THE “ACCEPT”
          BUTTON, YOU ACKNOWLEDGE THAT: (1) YOU ARE 13 YEARS OF AGE OR OLDER,
          AND IF YOU ARE BETWEEN AGE 13 and 18, YOU HAVE OBTAINED CONSENT FROM
          YOUR PARENT OR GUARDIAN; AND (2) YOU HAVE READ, UNDERSTOOD, AND
          ACCEPTED THE TERMS AND CONDITIONS OF THIS AGREEMENT.”
        </p>
        <p>
          “Ownership and Copyright of Software Title to the Software and all
          copies thereof remain with Parallels and/or its suppliers. The
          Software is copyrighted and is protected by United States copyright
          laws and international treaty provisions. Licensee will not remove
          copyright notices from the Software. Licensee agrees to prevent any
          unauthorized copying of the Software. Except as expressly provided
          herein, Parallels does not grant any express or implied right to you
          under Parallels patents, copyrights, trademarks, or trade secret
          information.”
        </p>
        <p>
          “Limited License. You are entitled to access, download or install, and
          operate the Game solely for the purposes of performing your
          obligations under this Agreement. You may not sell, license, or
          transfer the Game, or reproductions of the Game to other parties In
          any way. You may download or Install, and operate the Game on Android
          devices linked to the email address provided on sign-up.”
        </p>
        <p>
          “Limitation on Liability Provision of any Software under this
          Agreement is experimental and shall not create any obligation for
          Paragoni Apps to continue to develop, productize, support, repair,
          offer for sale or in any other way continue to provide or develop
          Software either to Licensee or to any other party. THE SOFTWARE IS
          PROVIDED “AS IS” WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND
          INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR
          PURPOSE. IN NO EVENT SHALL Paragoni Apps OR ITS SUPPLIERS BE LIABLE
          FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
          LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING
          OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE, EVEN IF Paragoni
          Apps HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.”
        </p>
        <p>
          “TERM AND TERMINATION Unless otherwise terminated as specified under
          this Agreement. Licensee’s rights with respect to the Beta Software
          will terminate upon the earlier of (a) the initial commercial release
          by Talend of a generally available version of the Beta Software or (b)
          automatic expiration of the Beta Software based on the system date.
          Either party may terminate this Agreement at any time for any reason
          or no reason by providing the other party advance written notice
          thereof. Talend shall immediately terminate this Agreement and any
          Licensee rights with respect to the Beta Software without notice in
          the event of improper disclosure of Talend’s Beta Software as
          specified under Section 6 (Confidentiality) below. Upon any expiration
          or termination of this Agreement, the rights and licenses granted to
          Licensee under this Agreement shall immediately terminate, and
          Licensee shall immediately cease using, and will return to Talend (or,
          at Talend’s request, destroy), the Beta Software, Documentation, and
          all other tangible items in Licensee’s possession or control that are
          proprietary to or contain Confidential Information. The rights and
          obligations of the parties set forth in Sections 2, 3, 4, 5, 6, 7, 8 9
          and 10 shall survive termination or expiration of this Agreement for
          any reason.”
        </p>
        <p>
          “BETA TESTERS DUTIES Beta Tester agrees to report any flaws, errors or
          imperfections discovered in any software or other materials where Beta
          Tester has been granted access to the Beta Test. Beta Tester
          understands that prompt and accurate reporting is the purpose of the
          Beta Tests and undertakes to use best efforts to provide frequent
          reports on all aspects of the product both positive and negative and
          acknowledges that any improvements, modifications and changes arising
          from or in connection with the Beta Testers contribution to the
          Project, remain or become the exclusive property of the Disclosing
          Party”
        </p>
        <p>
          “Confidentiality The Tester will not disclose Software or any comments
          regarding Software to any third party without the prior written
          approval of Syngli. The Tester will maintain the confidentiality of
          Software with at least the same degree of care that you use to protect
          your own confidential and proprietary information, but not less than a
          reasonable degree of care under the circumstances. The Tester will not
          be liable for the disclosure of any confidential information which is:
          (a.) in the public domain other than by a breach of this Agreement on
          Tester’s part; or (b.) rightfully received from a third party without
          any obligation of confidentiality; or (c.) rightfully known to Tester
          without any limitation on use or disclosure prior to its receipt from
          Syngli; or (d.) generally made available to third parties by Syngli
          without restriction on disclosure.”
        </p>
        <p>
          “No Support and Maintenance; Future Products. During your
          participation in the Beta Program or in a particular seed. Apple is
          not obligated to provide you with any maintenance, technical or other
          support for the Pre-Release Software. If. at Apple’s option, such
          support is provided. it will be provided in addition to your normal
          warranty coverage for your computer and/or device. You agree to abide
          by any support rules and policies that Apple provides to you in order
          to receive such support. You acknowledge that Apple has no express or
          implied obligation to announce or make available a commercial version
          of the Pre-Release Software to anyone in the future. Should a
          commercial version be made available, it may have features or
          functionality that are different from those found in the Pre-Release
          Software licensed hereunder.”
        </p>
        <p>
          “Privacy Policy theScore’s Privacy Policy (available at
          http://www.thescore.com/pages/privacy) (Privacy Policy) applies to the
          Beta Program and the Beta Software. You acknowledge and agree that by
          participating in the Beta Program or by using the Beta Software,
          theScore may receive certain information about you. including
          personally identifiable information. and you hereby consent to
          theScore’s collection, use and disclosure such information in
          accordance with the Privacy Policy.”
        </p>
        <p>
          “Modification. This is the entire agreement between the parties
          relating to the subject matter hereof and all other terms are
          rejected. No waiver or modification of this Agreement shall be valid
          unless in writing signed by each party. The waiver of a breach of any
          term hereof shall in no way be construed as a waiver of any term or
          other breach hereof. If any provision of this Agreement is held by a
          court of competent jurisdiction to be contrary to law the remaining
          provisions of this Agreement shall remain in full force and effect.”
        </p>
        <p>
          “No Assignment. This Agreement is personal to Tester. Tester shall not
          assign or otherwise transfer any rights or obligations under this
          Agreement.”
        </p>
        <p>
          “If any provision of this Agreement shall be found by a court to be
          void, invalid or unenforceable, the same shall be reformed to comply
          with applicable law or stricken if not so conformable, so as not to
          affect the validity or enforceability of this Agreement.”
        </p>
        <p>
          “CHOICE OF LAW AND DISPUTES For other than the U.S. Government as a
          party, this Agreement shall be governed by and construed in accordance
          with the laws of the State of California. as If performed wholly
          within the state and without giving effect to the principles of
          conflict of law rules of any jurisdiction or the United Nations
          Convention on Contracts for the International Sale of Goods, the
          application of which is expressly excluded. Any legal action or
          proceeding arising under this Agreement will be brought exclusively in
          the federal or state courts located in San Francisco. California and
          the parties hereby consent to personal Jurisdiction and venue
          therein.”
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button style={modalButton} onClick={props.onHide}>
          close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
