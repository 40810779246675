import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getUserPosts } from "../../redux/actions";
import { useParams } from "react-router-dom";
import CategoryPostForm from "./CategoryPostForm";
import { Card } from "react-bootstrap";

const ForYou = (props) => {
  const userId = localStorage.getItem("user_id");
  const { getUserPosts } = props;
  const [postSuccess, setPostSuccess] = useState(false);
  const { category } = useParams();

  useEffect(() => {
    getUserPosts(userId);
  }, [postSuccess, userId, getUserPosts]);

  const filterCompletedTopics = props.topics.filter(function (topic) {
    return !props.userPosts.some(function (post) {
      return topic.id === post.topic_id;
    });
  });

  function recommendedTopics() {
    if (!filterCompletedTopics.length) {
      return (
        <div>
          You're out of new topics to discuss! Come back later for more.
        </div>
      );
    } else {
      const allUserPosts = props.userPosts;
      return (
        <>
          {filterCompletedTopics.map((topic) => {
            const postMatchesTopic = allUserPosts.filter((post) =>
              post.topic_id === topic.id ? post : null
            );
            if (!postMatchesTopic.length) {
              return (
                <Card key={topic.topic_name} className="topic-card-container">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="topic-title"
                  >
                    {topic.topic_name}
                    <div className="category-label">
                      {props.categories.map((category) => {
                        if (category.id === topic.category_id) {
                          return (
                            <div key={category.id}>
                              {category.category_name.toUpperCase()}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                  <div className="topic-body-container">
                    <CategoryPostForm
                      topics={props.topics}
                      topicId={topic.id}
                      postSuccess={postSuccess}
                      setPostSuccess={setPostSuccess}
                    />
                  </div>
                </Card>
              );
            } else {
              return null;
            }
          })}
        </>
      );
    }
  }

  return (
    <div className="category-container">
      <div className="category-title-container">
        <h1 className="category-title">{category}</h1>
      </div>
      {recommendedTopics()}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    userPosts: serverReducer.userPosts,
    isFetchingUserPosts: serverReducer.isFetchingUserPosts,
  };
};

export default connect(mapStateToProps, { getUserPosts })(ForYou);
