import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
//Components
import { Logout } from "../logout/Logout";
import EditProfileModal from "../profile/EditProfileModal";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";

const MobileNavFooter = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="mobile-nav-footer">
      <div className="mobile-tools-menu">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tool-tip-top`}>View Profile</Tooltip>}
        >
          <Link
            style={{ marginRight: "16px" }}
            to={`/home/profile/${props.user.user_name}`}
            className="notification-button-style"
          >
            <FontAwesomeIcon icon={faUserAstronaut} size="lg" />
          </Link>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tool-tip-top`}>Notifications</Tooltip>}
        >
          <Button
            style={{ background: "none", border: "none" }}
            href="/home/notifications"
            className="notification-button-style"
          >
            <FontAwesomeIcon icon={faBell} size="lg" />
          </Button>
        </OverlayTrigger>
        <NavDropdown
          drop={"up"}
          title={<FontAwesomeIcon icon={faCog} size="lg" />}
          id="basic-nav-dropdown"
        >
          <NavDropdown.Item href="/" onClick={Logout}>
            Logout
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => setModalShow(true)}>
            {" "}
            Edit Profile
          </NavDropdown.Item>
        </NavDropdown>
      </div>
      <EditProfileModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default MobileNavFooter;
