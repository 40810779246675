import React from "react";
import { Link } from "react-router-dom";
import LikesCardInfo from "./LikesCardInfo";
import CheckBox from "../../assets/icons/CheckBox";
import XBox from "../../assets/icons/XBox";

const LikesCard = (props) => {
  return (
    <div style={{ width: "100%" }}>
      {props.userLikes
        .filter((userLike) => userLike.user_name === props.user_name)
        .map((card) => {
          const postsArray = props.posts;
          for (let i = 0; i < postsArray.length; i++) {
            if (card.post_id === postsArray[i].id) {
              return (
                <div key={postsArray[i].id} className="likes-card-container">
                  {!postsArray[i].vote ? (
                    <div className="vote-status">
                      <XBox />
                    </div>
                  ) : (
                    <div className="vote-status">
                      <CheckBox />
                    </div>
                  )}
                  {props.topicNames
                    .filter(
                      (topicName) => topicName.id === postsArray[i].topic_id
                    )
                    .map((title) => {
                      return (
                        <h3
                          key={title.topic_name}
                          className="likes-topic-header"
                        >
                          {title.topic_name}
                        </h3>
                      );
                    })}
                  <Link
                    to={`/home/post/${postsArray[i].id}`}
                    className="likes-card-link"
                  >
                    <LikesCardInfo
                      postUserId={postsArray[i].user_id}
                      postId={postsArray[i].id}
                      postBody={postsArray[i].post_body}
                      createdAt={postsArray[i].created_at}
                      user_name={props.user_name}
                    />
                  </Link>
                </div>
              );
            }
          }
        })}
    </div>
  );
};

export default LikesCard;
