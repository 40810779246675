import React, { useState } from "react";
import { connect } from "react-redux";
import { editUserInfo } from "../../redux/actions";
import { Modal, Accordion, Card, AccordionCollapse } from "react-bootstrap";
import axiosWithAuth from "../../utils/axiosWithAuth";

function EditProfileModal(props) {
  const [input, setInput] = useState({
    firstname: props.user.firstname,
    lastname: props.user.lastname,
    user_name: props.user.user_name,
    user_bio: props.user.user_bio,
  });

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    console.log(input);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .put(`api/users/${props.user.id}`, input)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err, "unable to edit user");
      });
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{
              fontWeight: "bold",
              fontFamily:
                "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
            }}
          >
            Edit Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey="0">
            <Card
              style={{
                fontWeight: "bold",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
                cursor: "pointer",
              }}
            >
              <Accordion.Toggle as={Card.Header} eventKey="1">
                edit first name/ last name
              </Accordion.Toggle>
              <AccordionCollapse eventKey="1">
                <Card.Body>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="edit-name-container">
                      <input
                        style={{ margin: "3px" }}
                        type="text"
                        name="firstname"
                        value={input.firstname}
                        placeholder={props.user.firstname}
                        onChange={handleChange}
                      />
                      <input
                        style={{ margin: "3px" }}
                        type="text"
                        name="lastname"
                        value={input.lastname}
                        placeholder={props.user.lastname}
                        onChange={handleChange}
                      />
                    </div>
                    <button className="edit-profile-button">submit</button>
                  </form>
                </Card.Body>
              </AccordionCollapse>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                edit username
              </Accordion.Toggle>
              <AccordionCollapse eventKey="1">
                <Card.Body>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className="edit-username-container">
                      <input
                        style={{ margin: "6px" }}
                        type="text"
                        name="user_name"
                        value={input.user_name}
                        placeholder={props.user.user_name}
                        onChange={handleChange}
                      />
                    </div>
                    <button className="edit-profile-button">submit</button>
                  </form>
                </Card.Body>
              </AccordionCollapse>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                edit bio
              </Accordion.Toggle>
              <AccordionCollapse eventKey="1">
                <Card.Body>
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div className="edit-bio-container">
                      <textarea
                        style={{ width: "100%" }}
                        type="text"
                        name="user_bio"
                        value={input.user_bio}
                        placeholder={props.user.user_bio}
                        onChange={handleChange}
                      />
                    </div>
                    <br />
                    <button className="edit-profile-button">submit</button>
                  </form>
                </Card.Body>
              </AccordionCollapse>
            </Card>
          </Accordion>
          <br />
          <div className="change-password-container">
            <button className="change-password-button">change password</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isEditingUserInfo: serverReducer.isEditingUserInfo,
  };
};

export default connect(mapStateToProps, { editUserInfo })(EditProfileModal);
