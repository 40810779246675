import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import WelcomeModal from "./components/welcome/WelcomeModal";
import FormikRegister from "./components/login/Register";
import FormikLogin from "./components/login/Login";
import PrivacyModal from "./components/welcome/PrivacyModal";
import HelpModal from "./components/welcome/HelpModal";

const Welcome = (props) => {
  const history = useHistory();
  const [showRegister, setShowRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalShow, setModalShow] = useState(true);
  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  const [helpModalShow, setHelpModalShow] = useState(false);

  return (
    <>
      <div className="welcome-container">
        <div className="welcome-instructions">
          {/* <h2>Welcome to vennthink</h2>
                <br/> */}
          <h3>An alternative social experience</h3>
        </div>
        {showRegister ? (
          <FormikRegister
            history={history}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setShowRegister={setShowRegister}
            showError={showError}
            setShowError={setShowError}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        ) : (
          <FormikLogin
            history={history}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setShowRegister={setShowRegister}
            showError={showError}
            setShowError={setShowError}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        )}
      </div>
      <div className="welcome-modal">
        <WelcomeModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
      <div className="welcome-modal">
        <PrivacyModal
          show={privacyModalShow}
          onHide={() => setPrivacyModalShow(false)}
        />
      </div>
      <div className="welcome-modal">
        <HelpModal
          show={helpModalShow}
          onHide={() => setHelpModalShow(false)}
        />
      </div>
      <footer className="welcome-footer">
        <ul className="welcome-footer-links">
          <button
            className="footer-button"
            onClick={() =>
              !privacyModalShow
                ? setPrivacyModalShow(true)
                : setPrivacyModalShow(false)
            }
          >
            privacy
          </button>
          <p style={{ marginRight: "5%", marginLeft: "5%" }}>
            vennthink, inc &copy; 2019
          </p>
          <button
            className="footer-button"
            onClick={() =>
              !helpModalShow ? setHelpModalShow(true) : setHelpModalShow(false)
            }
          >
            help
          </button>
        </ul>
      </footer>
    </>
  );
};

export default Welcome;
