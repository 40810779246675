import React, { useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";

const CommentReply = (props) => {
  const { commentId, postId, replySubmit, setReplySubmit } = props;
  const userId = localStorage.getItem("user_id");

  const [inputReply, setInputReply] = useState({
    comment_body: "",
    parent_id: commentId,
    post_id: postId,
    user_id: userId,
  });
  console.log(postId, commentId);

  const handleReplyChange = (e) => {
    setInputReply({ ...inputReply, [e.target.name]: e.target.value });
  };

  const handleReplySubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`api/posts/${postId}/comments`, inputReply)
      .then((res) =>
        !replySubmit ? setReplySubmit(true) : setReplySubmit(false)
      )
      .then((res) =>
        setInputReply({
          comment_body: "",
          parent_id: commentId,
          post_id: postId,
          user_id: userId,
        })
      )
      .catch((err) => console.log(err))
      .catch((err) => console.log(err));
  };
  return (
    <div className="reply-form-container">
      <form className="reply-form" onSubmit={handleReplySubmit}>
        <textarea
          className="reply-input"
          placeholder="Reply"
          onChange={handleReplyChange}
          name="comment_body"
          value={inputReply.comment_body}
          maxLength="500"
          spellCheck={true}
          required
        />
        <button className="reply-submit-button">submit</button>
      </form>
    </div>
  );
};

export default CommentReply;
