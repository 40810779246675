import React, { useEffect, useState } from "react";
import SourceForm from "./SourceForm";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Spinner from "../spinners/spinner";
import SourceCard from "./SourceCard";

const SourcesList = (props) => {
  const user_name = props.user_name;
  const { homeUserId, profileUserId, profileSources, sourceSubmit } = props;
  const [sources, setSources] = useState([]);

  useEffect(() => {
    setSources(profileSources);
  }, [profileSources, sourceSubmit]);

  function checkUserShowSourceForm() {
    if (homeUserId === profileUserId.toString()) {
      return (
        <SourceForm
          sourceSubmit={props.sourceSubmit}
          setSourceSubmit={props.setSourceSubmit}
          homeUserId={props.homeUserId}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div className="likes-list-container">
      <div className="post-list-header">
        <h3>{user_name.toUpperCase()}'S SOURCES</h3>
        <DropdownButton
          title="All"
          variant="light"
          className="filter-drop-down"
        >
          <Dropdown.Item eventKey="1" active>
            All
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">Most recent</Dropdown.Item>
          <Dropdown.Item eventKey="3">Highest rated</Dropdown.Item>
        </DropdownButton>
      </div>
      {checkUserShowSourceForm()}
      {!sources ? (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner width="38" height="38" color="#FF3781" />
        </span>
      ) : sources.length < 1 ? (
        <h4>No sources yet.</h4>
      ) : (
        <div className="source-list">
          {sources.map((source) => (
            <SourceCard
              key={source.id}
              user_name={user_name}
              homeUserId={homeUserId}
              sourceId={source.id}
              sourceUserId={source.user_id}
              sourceName={source.source_name}
              sourceUrl={source.source_url}
              sourceBody={source.source_body}
              createdAt={source.created_at}
              setToggleDelete={props.setToggleDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SourcesList;
