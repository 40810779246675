import React from "react";
import { Link } from "react-router-dom";
import OppositeOpinionUser from "./OppositeOpinionUser";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import CommentIcon from "../../assets/icons/CommentIcon";
import Moment from "moment";
import CheckBox from "../../assets/icons/CheckBox";
import XBox from "../../assets/icons/XBox";
import OppositeOpinionScore from "./OppositeOpinionScore";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OppositeOpinionCard = (props) => {
  function checkForVote(vote) {
    if (vote === true) {
      return <CheckBox style={{ marginRight: "15px" }} />;
    } else {
      return <XBox style={{ marginRight: "15px" }} />;
    }
  }

  return (
    <>
      <span
        style={{
          marginTop: "-32px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {checkForVote(props.vote)}
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="opposite-post-body-container">
          <OppositeOpinionScore
            score={props.score}
            postId={props.postId}
            allLikes={props.allLikes}
            user={props.user}
          />
          <div className="post-interior-container">
            <OppositeOpinionUser
              user_id={props.user_id}
              post_body={props.post_body}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <p className="post-user-log">
          Posted {Moment(props.created_at).calendar()}
        </p>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view discussion</Tooltip>}
        >
          <Link to={`/home/post/${props.postId}`} className="button-style">
            <CommentIcon />
          </Link>
        </OverlayTrigger>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              padding: "0",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <button
              style={{ background: "none", border: "none", outline: "none" }}
              to={"#/action-1"}
            >
              report
            </button>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default OppositeOpinionCard;
