import React from "react";
// import axiosWithAuth from '../../utils/axiosWithAuth';
import { Redirect } from "react-router-dom";

export const Logout = (e) => {
  e.preventDefault();
  let promises = [
    localStorage.removeItem("token"),
    localStorage.removeItem("user_id"),
  ];
  Promise.all(promises).then(() => {
    window.location.reload(false);
    return <Redirect to="/" />;
  });
};
