import React from "react";

export default function DownVote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      width="20px"
      height="20px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 32 32"
    >
      <g transform="translate(0 32) scale(1 -1)">
        <path
          d="M16 3.594l-.72.687l-7 7l1.44 1.44L15 7.436V24h2V7.437l5.28 5.282l1.44-1.44l-7-7l-.72-.686zM7 26v2h18v-2H7z"
          fill="#D54475"
        />
      </g>
    </svg>
  );
}
