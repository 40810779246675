import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
import LikesCard from "./LikesCard";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Spinner from "../spinners/spinner";

const LikesList = (props) => {
  const user_name = props.user_name;

  // get all topics
  const [topicNames, setTopicNames] = useState([]);
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadTopics = async () => {
      try {
        await axiosWithAuth()
          .get("/api/topics/all", { cancelToken: source.token })
          .then((res) => {
            setTopicNames(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadTopics();
    return () => {
      source.cancel();
    };
  }, []);

  // get all posts
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadPosts = async () => {
      try {
        await axiosWithAuth()
          .get("/api/posts", { cancelToken: source.token })
          .then((res) => {
            setPosts(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadPosts();
    return () => {
      source.cancel();
    };
  }, []);

  // get all likes
  const [userLikes, setUserLikes] = useState([]);
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadLikes = async () => {
      try {
        await axiosWithAuth()
          .get("/api/likes", { cancelToken: source.token })
          .then((res) => {
            setUserLikes(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadLikes();
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <div className="likes-list-container">
      <div className="post-list-header">
        <h3>{user_name.toUpperCase()}'S LIKED POSTS</h3>
        <DropdownButton
          title="All"
          variant="light"
          className="filter-drop-down"
        >
          <Dropdown.Item eventKey="1" active>
            All
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">Most recent</Dropdown.Item>
          <Dropdown.Item eventKey="3">Highest rated</Dropdown.Item>
        </DropdownButton>
      </div>
      {!userLikes.length ||
      !posts.length ||
      !topicNames.length ||
      !user_name ? (
        <Spinner width="38" height="38" color="#FF3781" />
      ) : (
        <LikesCard
          topicNames={topicNames}
          userLikes={userLikes}
          posts={posts}
          user_name={user_name}
        />
      )}
    </div>
  );
};

export default LikesList;
