import React from "react";
import { Modal, Button } from "react-bootstrap";

const modalStyle = {
  color: "#333333",
  fontWeight: "bold",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const modalBodyStyle = {
  color: "#333333",
  fontWeight: "normal",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const modalButton = {
  marginTop: "2%",
  padding: "1% 3% 1% 3%",
  borderRadius: "30px",
  background: "#F2857B",
  color: "white",
  fontWeight: "bold",
  borderStyle: "none",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
};

export default function WelcomeModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      area-labelledby="contained-modal-title-vcenter"
      centered
      style={modalStyle}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Welcome to Vennthink!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <p>
          This application is currently in beta. Although we have taken steps to
          ensure the security of your personal information, we encourage you to
          use dummy e-mails, and unique username/password combinations not
          shared with other applications.
          <br />
          <br /> - VT development team
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button style={modalButton} onClick={props.onHide}>
          close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
