import React, { useEffect } from "react";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";
import { connect } from "react-redux";
import { getTopicPosts } from "../../redux/actions/serverActions";

const VoteChart = (props) => {
  const { topic_id, getTopicPosts, topicPosts } = props;

  useEffect(() => {
    getTopicPosts(topic_id);
  }, [topic_id, getTopicPosts]);

  function voteData() {
    const upVotes = [];
    const downVotes = [];
    const data = topicPosts;
    if (!data.length) {
      return [
        { name: "Agree", agree: 0 },
        { name: "Disagree", disagree: 0 },
      ];
    } else {
      for (let i = 0; i < data.length; i++) {
        if (data[i].vote === true) {
          upVotes.push(data[i]);
        } else {
          downVotes.push(data[i]);
        }
      }
      return [
        { name: "", agree: upVotes.length },
        { name: "", disagree: downVotes.length },
      ];
    }
  }

  return (
    <BarChart
      width={500}
      height={300}
      cursor={"pointer"}
      data={voteData()}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey="name" fontSize="10px" />
      <Tooltip />
      <Bar dataKey="agree" fill="#F2857B" barSize={40} />
      <Bar dataKey="disagree" fill="#F2857B" barSize={40} />
    </BarChart>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    topicPosts: serverReducer.topicPosts,
    isFetchingTopicPosts: serverReducer.isFetchingTopicPosts,
  };
};

export default connect(mapStateToProps, { getTopicPosts })(VoteChart);
