import React from "react";
import Moment from "moment";
import { connect } from "react-redux";
// Components
import { ProgressBar, OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import { addUserPhoto } from "../../redux/actions/serverActions";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentAlt,
  faCheckCircle,
  faTimesCircle,
  faUserFriends,
  faUserCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Calendar from "./Calendar";

const UserInfo = (props) => {
  const { user } = props;

  function checkPhoto() {
    if (props.user.photo_url === null) {
      return (
        <img
          className="user-img"
          src="https://via.placeholder.com/150"
          alt="user"
        />
      );
    } else {
      return (
        <Image
          className="user-img"
          src={user.photo_url}
          alt="user"
          roundedCircle
        />
      );
    }
  }

  function disagreePostCount() {
    const disagreePosts = props.userPosts.filter((post) => post.vote === false);
    return disagreePosts.length;
  }
  function agreePostCount() {
    const agreePosts = props.userPosts.filter((post) => post.vote === true);
    return agreePosts.length;
  }

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("profileImage", image, image.name);
    props.addUserPhoto(props.user.id, formData);
  };

  const handleEditPicture = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  return (
    <div className="user-container">
      <div className="user-info">
        {checkPhoto()}
        <input
          type="file"
          id="imageInput"
          hidden="hidden"
          onChange={handleImageChange}
        />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tool-tip-top`}>Edit Photo</Tooltip>}
        >
          <button className="photo-edit-icon" onClick={handleEditPicture}>
            <FontAwesomeIcon icon={faEdit} size="1x" color="#FFFFF" />
          </button>
        </OverlayTrigger>
        <div className="user-name-box">
          <h3>@{props.user.user_name}</h3>
          <p>Joined {Moment(props.user.created_at).format("MMM DD YYYY")}</p>
          <p style={{ marginTop: "7px" }}>{props.user.user_bio}</p>
        </div>
      </div>
      <div className="user-rank">
        <h3>RANK</h3>
        <p>Gold</p>
        <ProgressBar
          className="user-rank-progress"
          variant="warning"
          now={props.userPosts.length}
        />
        <p id="rank-steps">{Math.floor(props.userPosts.length * 1.74)}</p>
      </div>
      <br />
      <div className="total-opinions">
        <h3>TOTAL OPINIONS</h3>
        <br />
        <h4>
          <span id="total-opinion-icon">
            <FontAwesomeIcon icon={faCommentAlt} size="lg" color="#FFFFF" />
          </span>{" "}
          {props.userPosts.length}
        </h4>
      </div>
      <div className="agree-disagree-container">
        <div className="total-posts">
          <h3>
            POSTS YOU
            <br /> AGREE WITH
          </h3>
          <h4 id="agree-posts">
            <span>
              <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#6FCF97" />
            </span>{" "}
            {agreePostCount()}
          </h4>
        </div>
        <div className="total-posts">
          <h3>
            POSTS YOU
            <br /> DISAGREE WITH
          </h3>
          <h4 id="disagree-posts">
            <span>
              <FontAwesomeIcon icon={faTimesCircle} size="lg" color="#EB5757" />
            </span>{" "}
            {disagreePostCount()}
          </h4>
        </div>
      </div>
      <div className="agree-disagree-container">
        <div className="user-followers">
          <h3>FOLLOWERS</h3>
          <h4 id="followers-count">
            <span>
              <FontAwesomeIcon icon={faUserCircle} size="lg" color="#FFFFF" />
            </span>{" "}
            {props.userFollowers.length}
          </h4>
        </div>
        <div className="user-followers">
          <h3>FOLLOWING</h3>
          <h4 id="following-count">
            <span>
              <FontAwesomeIcon icon={faUserFriends} size="lg" color="#FFFFF" />
            </span>{" "}
            {props.userFollowing.length}
          </h4>
        </div>
      </div>
      <br />
      <div className="contribution-chart">
        <h3>YOUR CONTRIBUTIONS</h3>
        <Calendar userPosts={props.userPosts} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    photo: serverReducer.photo,
    isAddingUserPhoto: serverReducer.isAddingUserPhoto,
  };
};

export default connect(mapStateToProps, { addUserPhoto })(UserInfo);
