import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Button, NavDropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//Components
import { useDarkMode } from "../customhooks/Darkmode";
import { Logout } from "../logout/Logout";
import Search from "./Search";
import EditProfileModal from "../profile/EditProfileModal";
//Icons
import LargeNavLogo from "../../assets/icons/LargeNavLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faUserAstronaut,
  faFire,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = (props) => {
  const [darkMode, setDarkMode] = useDarkMode(false);
  const [modalShow, setModalShow] = useState(false);

  const toggleMode = (e) => {
    e.preventDefault();
    setDarkMode(!darkMode);
  };

  return (
    <div className="nav-bar">
      <div className="nav-bar-icons">
        <Link
          to={`/home/profile/${props.user.user_name}`}
          className="venn-logo"
        >
          <LargeNavLogo style={{ height: "80%" }} />
        </Link>
        <div className="search-nav">
          <Search />
        </div>
      </div>
      <div className="nav-bar-categories">
        <NavLink
          exact={true}
          id="nav-trending"
          className="nav-links"
          activeClassName="is-active"
          to={"/home/trending"}
        >
          TRENDING
          <FontAwesomeIcon
            style={{ marginLeft: "5px" }}
            color="#FF3781"
            icon={faFire}
            size="lg"
          />
        </NavLink>
        <NavLink
          exact={true}
          className="nav-links"
          activeClassName="is-active"
          to={"/home/recommended"}
        >
          FOR YOU
        </NavLink>
        {props.categories.map(({ category_name, id }) => (
          <NavLink
            key={id}
            exact={true}
            className="nav-links"
            activeClassName="is-active"
            to={`/home/category/${id}`}
          >
            {category_name.toUpperCase()}
          </NavLink>
        ))}
        <NavLink
          exact={true}
          activeClassName="is-active"
          className="nav-links"
          to={"/home/activity"}
        >
          YOUR ACTIVITY
        </NavLink>
      </div>
      <div className="tools-menu">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>View Profile</Tooltip>}
        >
          <Link
            style={{ marginRight: "16px" }}
            to={`/home/profile/${props.user.user_name}`}
            className="notification-button-style"
          >
            <FontAwesomeIcon icon={faUserAstronaut} size="lg" />
          </Link>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>Notifications</Tooltip>}
        >
          <Button
            href="/home/notifications"
            className="notification-button-style"
          >
            <FontAwesomeIcon icon={faBell} size="lg" />
          </Button>
        </OverlayTrigger>
        <NavDropdown
          title={<FontAwesomeIcon icon={faCog} size="lg" />}
          id="basic-nav-dropdown"
        >
          <NavDropdown.Item href="/" onClick={Logout}>
            Logout
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => setModalShow(true)}>
            {" "}
            Edit Profile
          </NavDropdown.Item>
          <div className="dark-mode__toggle">
            <div
              onClick={toggleMode}
              className={darkMode ? "toggle toggled" : "toggle"}
            />
          </div>
        </NavDropdown>
      </div>
      <EditProfileModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Navbar;
