import React, { useState, useEffect } from "react";
import {
  NavLink,
  Route,
  Switch,
  useParams,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import {
  getProfilePosts,
  getProfileStatuses,
  getProfilePolls,
  getUserFollowing,
  unfollowUser,
  followUser,
  getProfileSources,
} from "./redux/actions";
// Components
import ProfilePostList from "./components/profile/ProfilePostList";
import EditProfileModal from "./components/profile/EditProfileModal";
import LikesList from "./components/profile/LikesList";
import SourcesList from "./components/sources/SourcesList";
import PollsList from "./components/statuspolls/PollsList";
import Spinner from "./components/spinners/spinner";
import FollowersModal from "./components/profile/FollowersModal";
import FollowingModal from "./components/profile/FollowingModal";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axiosWithAuth from "./utils/axiosWithAuth";
import Axios from "axios";

const Profile = (props) => {
  const history = useHistory();
  const homeUserId = localStorage.getItem("user_id");
  const { user_name } = useParams();
  const {
    getProfilePosts,
    getProfileStatuses,
    getProfilePolls,
    getUserFollowing,
    getProfileSources,
  } = props;
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    user_name: "",
    id: "",
    photo_url: "",
    user_bio: "",
  });
  const [toggleFollow, setToggleFollow] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [statusSubmit, setStatusSubmit] = useState(false);
  const [pollSubmit, setPollSubmit] = useState(false);
  const [sourceSubmit, setSourceSubmit] = useState(false);
  const [followersShow, setFollowersShow] = useState(false);
  const [followingShow, setFollowingShow] = useState(false);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUser = async () => {
      try {
        await axiosWithAuth()
          .get("/api/users", { cancelToken: source.token })
          .then((res) => {
            const result = res.data.filter(
              (user) => user.user_name === user_name
            );
            setUser({
              firstname: result[0].firstname,
              lastname: result[0].lastname,
              user_name: result[0].user_name,
              id: result[0].id,
              photo_url: result[0].photo_url,
              user_bio: result[0].user_bio,
            });
          })
          .catch((err) => console.log(err));
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUser();
    return () => {
      source.cancel();
    };
  }, [user_name, modalShow]);

  useEffect(() => {
    if (user.id === "") {
      console.log("waiting");
    } else {
      getProfilePosts(user.id);
      getProfileStatuses(user.id);
      getProfilePolls(user.id);
      getUserFollowing(homeUserId);
      getProfileSources(user.id);
    }
  }, [
    user.id,
    toggleFollow,
    homeUserId,
    toggleDelete,
    user_name,
    statusSubmit,
    modalShow,
    pollSubmit,
    getProfilePolls,
    getProfileStatuses,
    getProfilePosts,
    getUserFollowing,
    getProfileSources,
  ]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadFollowing = async () => {
      try {
        const id = user.id;
        axiosWithAuth()
          .get(`/api/users/${id}/following`, { cancelToken: source.token })
          .then((res) => {
            setFollowing(res.data);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadFollowing();
    return () => {
      source.cancel();
    };
  }, [user.id, toggleFollow]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUserFollowers = async () => {
      try {
        const id = user.id;
        axiosWithAuth()
          .get(`/api/users/${id}/followers`, { cancelToken: source.token })
          .then((res) => {
            setFollowers(res.data);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUserFollowers();
    return () => {
      source.cancel();
    };
  }, [user.id, toggleFollow]);

  function userImage() {
    if (!user.photo_url) {
      return (
        <img
          className="user-profile-img"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="user-profile-img"
          src={user.photo_url}
          alt="user profile"
        />
      );
    }
  }

  function addingFollow() {
    axiosWithAuth()
      .post(`/api/users/${homeUserId}/following`, { following_id: user.id })
      .then(toggleFollow ? setToggleFollow(false) : setToggleFollow(true))
      .catch((err) => {
        console.log(err);
      });
  }

  function followStatus() {
    const follows = props.userFollowing;
    const isFollowing = follows.filter(
      (follow) => follow.following_id === user.id
    );
    if (follows.length < 1) {
      if (Number(homeUserId) === user.id) {
        return (
          <button className="follow-button" onClick={() => setModalShow(true)}>
            edit profile
          </button>
        );
      } else {
        return (
          <button className="follow-button" onClick={addingFollow}>
            follow
          </button>
        );
      }
    } else if (user.id === Number(homeUserId)) {
      return (
        <button className="follow-button" onClick={() => setModalShow(true)}>
          edit profile
        </button>
      );
    } else if (isFollowing.length) {
      return (
        <button
          className="follow-button"
          onClick={(e) => {
            e.preventDefault();
            axiosWithAuth()
              .delete(`/api/following/${isFollowing[0].id}`)
              .then(
                toggleFollow ? setToggleFollow(false) : setToggleFollow(true)
              )
              .catch((err) => err);
          }}
        >
          following
        </button>
      );
    } else {
      return (
        <button onClick={addingFollow} className="follow-button">
          follow
        </button>
      );
    }
  }

  return (
    <div className="user-profile-container">
      <div className="user-profile-top">
        <button
          href={"/notifications"}
          className="back-button-style"
          onClick={history.goBack}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
      </div>
      <div className="user-profile-info">
        {userImage()}
        <div className="user-description">
          <h3>
            {user.firstname} {user.lastname}
          </h3>
          <p>@{user.user_name}</p>
          <p>{user.user_bio}...</p>
        </div>
      </div>
      <div className="follow-section">
        {followStatus()}
        <button
          disabled={!following.length}
          class="follow-button"
          onClick={() => setFollowingShow(true)}
        >
          {following.length} following
        </button>
        <button
          disabled={!followers.length}
          class="follow-button"
          onClick={() => setFollowersShow(true)}
        >
          {followers.length} followers
        </button>
      </div>
      <div className="user-profile-tabs">
        <NavLink
          exact={true}
          to={`/home/profile/${user_name}`}
          activeClassName="is-active"
          className="nav-links"
        >
          OPINIONS
        </NavLink>
        <NavLink
          exact={true}
          to={`/home/profile/${user_name}/polls`}
          activeClassName="is-active"
          className="nav-links"
        >
          MY POLLS
        </NavLink>
        <NavLink
          exact={true}
          to={`/home/profile/${user_name}/sources`}
          activeClassName="is-active"
          className="nav-links"
        >
          MY SOURCES
        </NavLink>
        <NavLink
          exact={true}
          to={`/home/profile/${user_name}/liked`}
          activeClassName="is-active"
          className="nav-links"
        >
          LIKED
        </NavLink>
      </div>
      <Switch>
        <Route exact path="/home/profile/:user_name">
          {!props.profileStatuses && !props.profilePosts && !user.photo_url ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Spinner width="38" height="38" color="#FF3781" />
            </span>
          ) : (
            <ProfilePostList
              setStatusSubmit={setStatusSubmit}
              statusSubmit={statusSubmit}
              profileStatuses={props.profileStatuses}
              profilePosts={props.profilePosts}
              user_name={user.user_name}
              setToggleDelete={setToggleDelete}
              toggleDelete={toggleDelete}
              homeUserId={homeUserId}
              profileUserId={user.id}
              user_image={user.photo_url}
              pollSubmit={pollSubmit}
              setPollSubmit={setPollSubmit}
            />
          )}
        </Route>
        <Route exact path="/home/profile/:user_name/liked">
          <LikesList user_name={user.user_name} />
        </Route>
        <Route exact path="/home/profile/:user_name/polls">
          {!props.profilePolls ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Spinner width="38" height="38" color="#FF3781" />
            </span>
          ) : (
            <PollsList
              profileUserId={user.id}
              pollSubmit={pollSubmit}
              setPollSubmit={setPollSubmit}
              homeUserId={homeUserId}
              user_name={user.user_name}
              profilePolls={props.profilePolls}
              setToggleDelete={setToggleDelete}
            />
          )}
        </Route>
        <Route exact path="/home/profile/:user_name/sources">
          <SourcesList
            profileUserId={user.id}
            homeUserId={homeUserId}
            user_name={user.user_name}
            setToggleDelete={setToggleDelete}
            profileSources={props.profileSources}
            sourceSubmit={sourceSubmit}
            setSourceSubmit={setSourceSubmit}
          />
        </Route>
        <Route exact path="" />
      </Switch>
      <EditProfileModal show={modalShow} onHide={() => setModalShow(false)} />
      <FollowersModal
        followers={followers}
        followersShow={followersShow}
        setFollowersShow={setFollowersShow}
      />
      <FollowingModal
        following={following}
        followingShow={followingShow}
        setFollowingShow={setFollowingShow}
      />
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    profilePosts: serverReducer.profilePosts,
    isFetchingProfilePosts: serverReducer.isFetchingProfilePosts,
    profileStatuses: serverReducer.profileStatuses,
    isFetchingProfileStatuses: serverReducer.isFetchingProfileStatuses,
    profilePolls: serverReducer.profilePolls,
    isFetchingProfilePolls: serverReducer.isFetchingProfilePolls,
    userFollowing: serverReducer.userFollowing,
    isFetchingUserFollowing: serverReducer.isFetchingUserFollowing,
    isUnfollowingUser: serverReducer.isUnfollowingUser,
    isFollowingUser: serverReducer.isFollowingUser,
    profileSources: serverReducer.profileSources,
    isFetchingProfileSources: serverReducer.isFetchingProfileSources,
  };
};

export default connect(mapStateToProps, {
  getProfilePosts,
  getProfileStatuses,
  getProfilePolls,
  getUserFollowing,
  unfollowUser,
  followUser,
  getProfileSources,
})(Profile);
