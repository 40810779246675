import React from "react";
// import Moment from 'moment';
import { Link } from "react-router-dom";
// import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TrendingTopicsCard = (props) => {
  return (
    <div>
      <div className="trend-card">
        <div className="trend-post-interior-container">
          <span className="trend-post-handle">
            <Link to={`/home/category/${props.categoryId}`}>
              {props.topicName}
            </Link>
            {/* <span className='trending-status'>
                            <FontAwesomeIcon icon={faLongArrowAltUp} size='1x' style={{color:'#6FCF97'}} />
                            <h4 style={{color:'#828282'}}>{props.likes}</h4>
                        </span> */}
          </span>
          {/* <p className='trend-post-body'>
                        {props.post_body}
                    </p> */}
        </div>
        <Link
          className="trend-chevron-right"
          to={`/home/category/${props.categoryId}`}
        >
          view
        </Link>
        {/* <p className='trend-post-log'>Posted {Moment(props.created_at).calendar()}</p> */}
      </div>
      <div style={{ border: "1px solid #E0E0E0" }} />
    </div>
  );
};

export default TrendingTopicsCard;
