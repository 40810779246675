import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import PollForm from "./PollForm";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
// import 'emoji-mart/css/emoji-mart.css';
// import data from 'emoji-mart/data/twitter.json'
// import { Picker } from 'emoji-mart';
import {
  faSmile,
  faImage,
  faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StatusForm(props) {
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [showPollForm, setShowPollForm] = useState(false);

  const [inputStatus, setInputStatus] = useState({
    status_title: "",
    status_body: "",
  });

  // const addEmoji = e => {
  //     let emoji = e.native;
  //     setInputStatus({
  //         status_body: inputStatus.status_body + emoji
  //     });
  // };

  const handleChange = (e) => {
    setInputStatus({ ...inputStatus, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`api/users/${props.homeUserId}/status`, inputStatus)
      .then((res) => {
        console.log("add user status", res);
        !props.statusSubmit
          ? props.setStatusSubmit(true)
          : props.setStatusSubmit(false);
        setInputStatus({
          status_title: "",
          status_body: "",
        });
      })
      .catch((err) => {
        console.log("add status error", err);
      });
  };

  function renderUserImage() {
    if (!props.user_image) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="post-user-image"
          src={props.user_image}
          alt="user profile"
        />
      );
    }
  }

  return (
    <div className="comment-form-container">
      <span>{renderUserImage()}</span>
      <Form className="comment-form" onSubmit={handleSubmit}>
        <input
          className="status-title-input"
          placeholder="Topic"
          onChange={handleChange}
          name="status_title"
          value={inputStatus.status_title}
          maxLength="800"
          spellCheck={true}
          required
        />
        <textarea
          style={{ marginTop: "6px" }}
          className="comment-form-text"
          placeholder="What do you think?"
          onChange={handleChange}
          name="status_body"
          value={inputStatus.status_body}
          maxLength="800"
          spellCheck={true}
          required
        />
        <div className="comment-form-footer">
          <span
            className="comment-icon-button"
            onClick={() => setShowPollForm(true)}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tool-tip-top`}>create poll</Tooltip>}
            >
              <FontAwesomeIcon icon={faChartBar} size="lg" />
            </OverlayTrigger>
          </span>

          {/* <span className='comment-icon-button' onClick={() => !showEmojiPicker ? setShowEmojiPicker(true) : setShowEmojiPicker(false)}>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id={`tool-tip-top`}>
                                    add emoji
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon icon={faSmile} size='lg' />
                        </OverlayTrigger>
                    </span> */}
          <span className="comment-icon-button">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tool-tip-top`}>coming soon!</Tooltip>}
            >
              <FontAwesomeIcon icon={faImage} size="lg" />
            </OverlayTrigger>
          </span>
          <button className="comment-form-button">submit</button>
        </div>
      </Form>
      <PollForm
        homeUserId={props.homeUserId}
        show={showPollForm}
        onHide={() => setShowPollForm(false)}
        setShowPollForm={setShowPollForm}
        showPollForm={showPollForm}
        pollSubmit={props.pollSubmit}
        setPollSubmit={props.setPollSubmit}
      />
      {/* {
                !!showEmojiPicker ? 
                    <div className='emoji-picker'>
                        <Picker 
                            set='twitter' 
                            data={data} 
                            title='select emoji!'
                            emoji='point_up'
                            onSelect={addEmoji} 
                        />
                    </div> : <span></span>
            } */}
    </div>
  );
}
