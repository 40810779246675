import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import axios from "axios";

const modalStyle = {
  color: "#333333",
  fontWeight: "bold",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const modalBodyStyle = {
  color: "#333333",
  fontWeight: "normal",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
};

const modalButton = {
  marginTop: "2%",
  padding: "1% 3% 1% 3%",
  borderRadius: "30px",
  background: "#F2857B",
  color: "white",
  fontWeight: "bold",
  borderStyle: "none",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
};

export default function HelpModal(props) {
  const [inputMessage, setInputMessage] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formResponse, setFormResponse] = useState(null);

  const handleChange = (e) => {
    setInputMessage({ ...inputMessage, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/contact", inputMessage)
      .then((res) => {
        console.log(res);
        setFormResponse(true);
      })
      .catch((err) => {
        console.log(err);
        setFormResponse(false);
      });
  };

  function renderFormResponse() {
    if (formResponse === null) {
      return <span></span>;
    } else if (formResponse) {
      return <Alert variant="success">message sent!</Alert>;
    } else {
      return <Alert variant="danger">message failed!</Alert>;
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      area-labelledby="contained-modal-title-vcenter"
      centered
      style={modalStyle}
    >
      {renderFormResponse()}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Questions or Concerns?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Who are you?</Form.Label>
            <Form.Control
              type="text"
              placeholder="name"
              name="name"
              value={inputMessage.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              name="email"
              value={inputMessage.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>What's this related to?</Form.Label>
            <Form.Control as="select">
              <option>General</option>
              <option>Feedback</option>
              <option>Career Opportunities</option>
              <option>Partnerships</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="please elaborate"
              rows="3"
              name="message"
              value={inputMessage.message}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button style={modalButton} type="submit">
            Send
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button style={modalButton} onClick={props.onHide}>
          close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
