import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
// Icons
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentIcon from "../../assets/icons/CommentIcon";

const StatusCard = (props) => {
  const homeUserId = localStorage.getItem("user_id");
  const [statusUser, setStatusUser] = useState({
    user_name: "",
    photo_url: "",
  });
  const { user_id } = props;

  function renderUserImage() {
    if (!statusUser.photo_url) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="post-user-image"
          src={statusUser.photo_url}
          alt="user profile"
        />
      );
    }
  }

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUser = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/users/${user_id}`, { cancelToken: source.token })
          .then((res) => {
            setStatusUser(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUser();
    return () => {
      source.cancel();
    };
  }, [props.id, user_id]);

  function deleteStatus() {
    axiosWithAuth()
      .delete(`/api/status/${props.id}`)
      .then((res) => {
        console.log(res);
        !props.toggleDelete
          ? props.setToggleDelete(true)
          : props.setToggleDelete(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkIfUserShowOptions() {
    if (user_id === Number(homeUserId)) {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div
              style={{ background: "none", border: "none", outline: "none" }}
              className="post-options-button"
            >
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu size="sm">
            <button
              style={{
                background: "none",
                border: "none",
                outline: "none",
                display: "flex",
              }}
              onClick={deleteStatus}
            >
              delete
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div
              style={{ background: "none", border: "none", outline: "none" }}
              className="post-options-button"
            >
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <button
              style={{
                background: "none",
                border: "none",
                outline: "none",
                display: "flex",
              }}
              to={"#/action-1"}
            >
              report
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  return (
    <div className="status-card">
      <div className="status-card-interior">
        {renderUserImage()}
        <div className="status-body">
          <span style={{ fontWeight: "bold" }}>
            <Link to={`/home/profile/${statusUser.user_name}`}>
              {statusUser.user_name}
            </Link>
            <br />
            <h3>{props.status_title}</h3>
          </span>
          <p style={{ fontWeight: "normal" }}>{props.status_body}</p>
        </div>
      </div>
      <div className="status-bottom-container">
        <p className="status-user-log">
          Posted {Moment(props.created_at).calendar()}
        </p>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={"tool-tip-top"}>view discussion</Tooltip>}
        >
          <Link to={`/home/status/${props.id}`} className="status-button-style">
            <CommentIcon />
          </Link>
        </OverlayTrigger>
        {checkIfUserShowOptions()}
      </div>
    </div>
  );
};

export default StatusCard;
