import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getPollByPollId } from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PollCard from "./PollCard";
import ResponseList from "./ResponseList";

const SinglePoll = (props) => {
  const history = useHistory();
  const { getPollByPollId } = props;
  const { id } = useParams();

  useEffect(() => {
    getPollByPollId(id);
  }, [id, getPollByPollId]);

  return (
    <div>
      <div className="single-post-header-container">
        <button className="back-button-style" onClick={history.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <h3 className="single-post-header">COMMUNITY OPINION</h3>
      </div>
      <PollCard
        isFetchingSinglePoll={props.isFetchingSinglePoll}
        pollId={props.singlePoll.id}
        pollUserId={props.singlePoll.user_id}
        pollTitle={props.singlePoll.poll_title}
        pollBody={props.singlePoll.poll_body}
        createdAt={props.singlePoll.created_at}
        setToggleDelete={props.setToggleDelete}
      />
      <ResponseList
        responses={props.singlePoll.responses}
        options={props.singlePoll.options}
      />
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    singlePoll: serverReducer.singlePoll,
    isFetchingSinglePoll: serverReducer.isFetchingSinglePoll,
  };
};

export default connect(mapStateToProps, { getPollByPollId })(SinglePoll);
