import React from "react";
import PollResponseForm from "./PollResponseForm";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";
import Spinner from "../spinners/spinner";

const UserPollOpinion = (props) => {
  function renderData(options, responses) {
    const homeUserId = localStorage.getItem("user_id");
    const responseSubmitted = responses.filter(
      (response) => response.user_id === Number(homeUserId)
    );

    if (!responses.length) {
      return (
        <div>
          <h4 className="no-responses-message">
            Come back later to view results!
          </h4>
          {options.map((option) => {
            return (
              <PollResponseForm
                setVoteSubmitted={props.setVoteSubmitted}
                pollId={option.poll_id}
                homeUserId={homeUserId}
                option={option}
              />
            );
          })}
        </div>
      );
    } else if (!responseSubmitted.length) {
      return (
        <div>
          {options.map((option) => {
            return (
              <PollResponseForm
                setVoteSubmitted={props.setVoteSubmitted}
                pollId={option.poll_id}
                homeUserId={homeUserId}
                option={option}
              />
            );
          })}
        </div>
      );
    } else {
      // construct dictionary of option ids with an initialized vote count of zero for each option
      const dict = {};
      options.forEach((option) => (dict[option.id] = 0));
      // loop through all responses for the given poll, and increment the value for that options key
      if (responses) {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].option_id in dict) {
            dict[responses[i].option_id] += 1;
          }
        }
      }
      // map over options and access the dictionary to get total votes per option
      const data = options.map((option) => {
        return { name: option.option_desc, votes: dict[option.id] };
      });

      const userSelection = options.filter(
        (option) => option.id === responseSubmitted[0].option_id
      );

      return (
        <div>
          <div>
            <h4 style={{ fontSize: "14px" }} className="poll-body">
              You selected: {userSelection[0].option_desc}
            </h4>
            <p
              style={{
                fontWeight: "normal",
                display: "flex",
                flexDirection: "column",
                marginLeft: "2%",
                marginRight: "2%",
              }}
              className="poll-body"
            >
              <span style={{ fontWeight: "bold" }}>Your opinion:</span>{" "}
              {responseSubmitted[0].response_body}
            </p>
          </div>
          <BarChart
            width={500}
            height={300}
            cursor={"pointer"}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" fontSize="10px" />
            <Tooltip />
            <Bar dataKey="votes" fill="#F2857B" barSize={40} />
          </BarChart>
        </div>
      );
    }
  }

  return !!props.isFetchingSinglePoll ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner width="38" height="38" color="#FF3781" />
    </div>
  ) : (
    <div>
      {!props.singlePoll.options ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner width="38" height="38" color="#FF3781" />
        </div>
      ) : (
        renderData(props.singlePoll.options, props.singlePoll.responses)
      )}
    </div>
  );
};

export default UserPollOpinion;
