import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUserInfo,
  getUserPosts,
  getCategories,
  getUserFollowing,
  getTrendingPosts,
} from "./redux/actions";
// Components
import axiosWithAuth from "./utils/axiosWithAuth";
import Axios from "axios";
import Spinner from "./components/spinners/spinner";
import UserInfo from "./components/profile/UserInfo";
import TrendList from "./components/profile/TrendList";
import Navbar from "./components/navigation/Navbar";
import MobileNavHeader from "./components/navigation/MobileNavHeader";
import PostsContainer from "./components/topiccarousel/PostsContainer";
import MobileNavFooter from "./components/navigation/MobileNavFooter";

const Home = (props) => {
  const homeUserId = localStorage.getItem("user_id");
  const [allLikes, setAllLikes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [userFollowers, setUserFollowers] = useState([]);
  const {
    getUserInfo,
    getUserPosts,
    getUserFollowing,
    getCategories,
    getTrendingPosts,
  } = props;

  useEffect(() => {
    getUserInfo(homeUserId);
    getUserPosts(homeUserId);
    getUserFollowing(homeUserId);
    getCategories();
    getTrendingPosts();
  }, [
    getUserInfo,
    getUserPosts,
    getUserFollowing,
    getCategories,
    getTrendingPosts,
    homeUserId,
  ]);

  useEffect(() => {
    axiosWithAuth()
      .get(`api/profileImage/${homeUserId}`)
      .then((res) => res)
      .catch((err) => console.log(err));
  }, [homeUserId]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUserFollowers = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/users/${homeUserId}/followers`, {
            cancelToken: source.token,
          })
          .then((res) => {
            setUserFollowers(res.data);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUserFollowers();
    return () => {
      source.cancel();
    };
  }, [homeUserId]);

  // Get all post likes
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadLikes = async () => {
      try {
        await axiosWithAuth()
          .get("/api/likes", { cancelToken: source.token })
          .then((res) => {
            setAllLikes(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadLikes();
    return () => {
      source.cancel();
    };
  }, []);

  // Get all topics
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadTopics = async () => {
      try {
        await axiosWithAuth()
          .get("/api/topics/all", { cancelToken: source.token })
          .then((res) => {
            setTopics(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadTopics();
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <div className="profile">
      <MobileNavHeader categories={props.categories} user={props.user} />
      <Navbar categories={props.categories} user={props.user} />
      <div className="profile-style">
        <div className="profile-info">
          <UserInfo
            user={props.user}
            userPosts={props.userPosts}
            userFollowing={props.userFollowing}
            userFollowers={userFollowers}
          />
        </div>
        <div className="profile-posts">
          {!props.categories ||
          !props.user ||
          !props.userFollowing ||
          !props.userPosts ? (
            <Spinner width="38" height="38" color="#FF3781" />
          ) : (
            <PostsContainer
              categories={props.categories}
              user={props.user}
              topics={topics}
              userPosts={props.userPosts}
              allLikes={allLikes}
              userFollowing={props.userFollowing}
              trendingPosts={props.trendingPosts}
            />
          )}
        </div>
        <div className="trends">
          <TrendList trendingPosts={props.trendingPosts} allLikes={allLikes} />
        </div>
      </div>
      <MobileNavFooter categories={props.categories} user={props.user} />
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    userId: serverReducer.userId,
    userPosts: serverReducer.userPosts,
    isFetchingUserPosts: serverReducer.isFetchingUserPosts,
    categories: serverReducer.categories,
    isFetchingCategories: serverReducer.isFetchingCategories,
    userFollowing: serverReducer.userFollowing,
    isFetchingUserFollowing: serverReducer.isFetchingUserFollowing,
    trendingPosts: serverReducer.trendingPosts,
  };
};

export default connect(mapStateToProps, {
  getUserInfo,
  getUserPosts,
  getCategories,
  getUserFollowing,
  getTrendingPosts,
})(Home);
