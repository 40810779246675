import axiosWithAuth from "../../utils/axiosWithAuth";
import constants from "../constants";
import Axios from "axios";

export const storeLogin = (userId) => {
  return { type: constants.STORE_LOGIN, payload: userId };
};

export const findUser = (searchTerms, history) => (dispatch) => {
  dispatch({ type: constants.FIND_USERINFO_START });
  axiosWithAuth()
    .post(`users/search`, searchTerms)
    .then((res) => {
      history.push(`/profile/${res.data.user_name}`);
    })
    .catch((err) => {
      history.push(`/search`);
      // Need to create a search return no results component
    });
};

export const getUserInfo = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadUserInfo = async () => {
    try {
      dispatch({ type: constants.FETCH_USERINFO_START });
      await axiosWithAuth()
        .get(`api/users/${userId}`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_USERINFO_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: constants.FETCH_USERINFO_FAILURE, payload: err });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadUserInfo();
  return () => {
    source.cancel();
  };
};

export const editUserInfo = (userToEdit) => (dispatch) => {
  dispatch({ type: constants.EDIT_USERINFO_START });
  const { firstname, lastname, user_name, user_bio } = userToEdit;
  const updatedUser = {
    firstname: userToEdit.firstname,
    lastname: userToEdit.lastname,
    user_name: userToEdit.user_name,
    user_bio: userToEdit.user_bio,
  };

  axiosWithAuth()
    .put(`api/users/${userToEdit.id}`, updatedUser)
    .then((res) => {
      dispatch({ type: constants.EDIT_USERINFO_SUCCESS, payload: userToEdit });
    })
    .catch((err) => {
      console.log(err, "unable to edit user");
      dispatch({ type: constants.EDIT_USERINFO_FAILURE, payload: err });
    });
};

// follow actions
export const followUser = (userId, followingId) => (dispatch) => {
  dispatch({ type: constants.FOLLOW_USER_START });
  axiosWithAuth()
    .post(`api/users/${userId}/following`, followingId)
    .then((res) => {
      dispatch({ type: constants.FOLLOW_USER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.FOLLOW_USER_FAILURE, payload: err });
    });
};

export const unfollowUser = (followId) => (dispatch) => {
  dispatch({ type: constants.UNFOLLOW_USER_START });
  axiosWithAuth()
    .delete(`api/users/${followId}/following`)
    .then((res) => {
      dispatch({ type: constants.UNFOLLOW_USER_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.UNFOLLOW_USER_FAILURE, payload: err });
    });
};

export const getUserFollowing = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadUserFollowing = async () => {
    try {
      dispatch({ type: constants.FETCH_USERFOLLOWING_START });
      await axiosWithAuth()
        .get(`api/users/${userId}/following`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_USERFOLLOWING_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_USERFOLLOWING_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadUserFollowing();
  return () => {
    source.cancel();
  };
};

export const addUserInfo = () => (dispatch) => {
  dispatch({ type: constants.ADD_USERINFO_START });
  axiosWithAuth()
    .post(`api/auth/register`)
    .then((res) => {
      dispatch({ type: constants.ADD_USERINFO_SUCCESS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.ADD_USERINFO_FAILURE });
    });
};

export const addUserPhoto = (userId, formData) => (dispatch) => {
  dispatch({ type: constants.ADD_USERPHOTO_START });
  axiosWithAuth()
    .post(`api/profileImage/${userId}/profile-img-upload`, formData)
    .then((res) => {
      dispatch({ type: constants.ADD_USERPHOTO_SUCCESS, formData });
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.ADD_USERPHOTO_FAILURE });
    });
};

export const deleteUserPhoto = (userId) => (dispatch) => {
  dispatch({ type: constants.DELETE_USERPHOTO_START });
  axiosWithAuth()
    .post(`api/users/${userId}/photo`)
    .then((res) => {
      dispatch({ type: constants.DELETE_USERPHOTO_SUCCESS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.DELETE_USERPHOTO_FAILURE });
    });
};

export const getCategories = () => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadCategories = async () => {
    try {
      dispatch({ type: constants.FETCH_CATEGORIES_START });
      await axiosWithAuth()
        .get(`api/categories`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_CATEGORIES_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: constants.FETCH_CATEGORIES_FAILURE });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadCategories();
  return () => {
    source.cancel();
  };
};

export const getTopicPosts = (topicId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadTopicPosts = async () => {
    try {
      dispatch({ type: constants.FETCH_TOPICPOSTS_START });
      await axiosWithAuth()
        .get(`api/topics/${topicId}/posts`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_TOPICPOSTS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: constants.FETCH_TOPICPOSTS_FAILURE });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadTopicPosts();
  return () => {
    source.cancel();
  };
};

export const addTopicPost = (inputPost, topicId) => (dispatch) => {
  dispatch({ type: constants.ADD_TOPICPOST_START });
  axiosWithAuth()
    .post(`api/topics/${topicId}/posts`, inputPost)
    .then((res) => {
      dispatch({ type: constants.ADD_TOPICPOST_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      console.log("saved posts error", err);
      dispatch({ type: constants.ADD_TOPICPOST_FAILURE, payload: err });
    });
};

export const getTrendingPosts = () => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadTrendingPosts = async () => {
    try {
      dispatch({ type: constants.FETCH_TRENDINGPOSTS_START });
      await axiosWithAuth()
        .get(`api/posts/trending`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_TRENDINGPOSTS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: constants.FETCH_TRENDINGPOSTS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadTrendingPosts();
  return () => {
    source.cancel();
  };
};

// Get opposite opinion posts
export const getOppositeOpinionPosts = (userVote, topic_id) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadOppositeOpinionPosts = async () => {
    try {
      dispatch({ type: constants.FETCH_OPPOSITEOPINIONS_START });
      await axiosWithAuth()
        .get(`api/topics/${topic_id}/posts`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_OPPOSITEOPINIONS_SUCCESS,
            payload: res.data.filter((post) => post.vote !== userVote),
          });
        })
        .catch((err) => {
          dispatch({
            type: constants.FETCH_OPPOSITEOPINIONS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadOppositeOpinionPosts();
  return () => {
    source.cancel();
  };
};

export const getUserPosts = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadUserPosts = async () => {
    try {
      dispatch({ type: constants.FETCH_USERPOSTS_START });
      await axiosWithAuth()
        .get(`api/users/${userId}/posts`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_USERPOSTS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: constants.FETCH_USERPOSTS_FAILURE, payload: err });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadUserPosts();
  return () => {
    source.cancel();
  };
};
// Get Profile Info
export const getProfilePosts = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadProfilePosts = async () => {
    try {
      dispatch({ type: constants.FETCH_PROFILEPOSTS_START });
      await axiosWithAuth()
        .get(`api/users/${userId}/posts`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_PROFILEPOSTS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_PROFILEPOSTS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadProfilePosts();
  return () => {
    source.cancel();
  };
};

export const getProfileStatuses = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadProfileStatuses = async () => {
    try {
      dispatch({ type: constants.FETCH_PROFILESTATUSES_START });
      await axiosWithAuth()
        .get(`api/users/${userId}/status`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_PROFILESTATUSES_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_PROFILESTATUSES_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadProfileStatuses();
  return () => {
    source.cancel();
  };
};

export const getProfileSources = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadProfileSources = async () => {
    try {
      dispatch({ type: constants.FETCH_PROFILESOURCES_START });
      await axiosWithAuth()
        .get(`api/users/${userId}/sources`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_PROFILESOURCES_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_PROFILESOURCES_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadProfileSources();
  return () => {
    source.cancel();
  };
};

export const getProfilePolls = (userId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadProfilePolls = async () => {
    try {
      dispatch({ type: constants.FETCH_PROFILEPOLLS_START });
      await axiosWithAuth()
        .get(`api/users/${userId}/polls`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_PROFILEPOLLS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_PROFILEPOLLS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadProfilePolls();
  return () => {
    source.cancel();
  };
};

export const getPollByPollId = (pollId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadPollByPollId = async () => {
    try {
      dispatch({ type: constants.FETCH_SINGLEPOLL_START });
      await axiosWithAuth()
        .get(`api/polls/${pollId}`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_SINGLEPOLL_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: constants.FETCH_SINGLEPOLL_FAILURE, payload: err });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadPollByPollId();
  return () => {
    source.cancel();
  };
};

export const getStatusByStatusId = (statusId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadStatusByStatusId = async () => {
    try {
      dispatch({ type: constants.FETCH_SINGLESTATUS_START });
      await axiosWithAuth()
        .get(`api/status/${statusId}`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_SINGLESTATUS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_SINGLESTATUS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadStatusByStatusId();
  return () => {
    source.cancel();
  };
};

export const addUserPost = (inputPost, userId) => (dispatch) => {
  dispatch({ type: constants.ADD_USERPOST_START });
  axiosWithAuth()
    .post(`api/users/${userId}/posts`, inputPost)
    .then((res) => {
      dispatch({ type: constants.ADD_USERPOST_SUCCESS, payload: inputPost });
    })
    .catch((err) => {
      console.log("add posts error", err);
      dispatch({ type: constants.ADD_USERPOST_FAILURE, payload: err });
    });
};

export const getSinglePost = (postId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadSinglePost = async () => {
    try {
      dispatch({ type: constants.FETCH_SINGLEPOST_START });
      await axiosWithAuth()
        .get(`api/posts/${postId}`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_SINGLEPOST_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({ type: constants.FETCH_SINGLEPOST_FAILURE, payload: err });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadSinglePost();
  return () => {
    source.cancel();
  };
};

export const editUserPost = (postToEdit) => (dispatch) => {
  dispatch({ type: constants.EDIT_USERPOST_START });
  const { vote, post_body } = postToEdit;
  const newPost = {
    post_body: postToEdit.post_body,
    vote: postToEdit.vote,
  };

  axiosWithAuth()
    .put(`api/posts/${postToEdit.id}`, newPost)
    .then((res) => {
      dispatch({ type: constants.EDIT_USERPOST_SUCCESS, payload: postToEdit });
    })
    .catch((err) => {
      console.log(err, "unable to edit post");
      dispatch({ type: constants.EDIT_USERPOST_FAILURE, payload: err });
    });
};

export const deleteUserPost = (id) => (dispatch) => {
  dispatch({ type: constants.DELETE_USERPOST_START });
  axiosWithAuth()
    .delete(`api/posts/${id}`)
    .then((res) => {
      dispatch({ type: constants.DELETE_USERPOST_SUCCESS, payload: id });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.DELETE_USERPOST_FAILURE, payload: err });
    });
};
// post comments
export const getPostComments = (postId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadPostComments = async () => {
    try {
      dispatch({ type: constants.FETCH_POSTCOMMENTS_START });
      await axiosWithAuth()
        .get(`api/posts/${postId}/comments`, { cancelToken: source.token })
        .then((res) => {
          dispatch({
            type: constants.FETCH_POSTCOMMENTS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_POSTCOMMENTS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadPostComments();
  return () => {
    source.cancel();
  };
};

export const addPostComment = (inputComment, postId) => (dispatch) => {
  dispatch({ type: constants.ADD_POSTCOMMENTS_START });
  axiosWithAuth()
    .post(`api/posts/${postId}/posts`, inputComment)
    .then((res) => {
      dispatch({
        type: constants.ADD_POSTCOMMENTS_SUCCESS,
        payload: inputComment,
      });
    })
    .catch((err) => {
      console.log("add comment error", err);
      dispatch({ type: constants.ADD_POSTCOMMENTS_FAILURE, payload: err });
    });
};

export const editPostComment = (commentToEdit) => (dispatch) => {
  dispatch({ type: constants.EDIT_POSTCOMMENTS_START });
  const { comment_body } = commentToEdit;
  const newComment = {
    comment_body: commentToEdit.comment_body,
  };

  axiosWithAuth()
    .put(`api/comments/${commentToEdit.id}`, newComment)
    .then((res) => {
      dispatch({
        type: constants.EDIT_POSTCOMMENTS_SUCCESS,
        payload: commentToEdit,
      });
    })
    .catch((err) => {
      console.log(err, "unable to edit comment");
      dispatch({ type: constants.EDIT_POSTCOMMENTS_FAILURE, payload: err });
    });
};

export const deletePostComment = (id) => (dispatch) => {
  dispatch({ type: constants.DELETE_POSTCOMMENTS_START });
  axiosWithAuth()
    .delete(`api/comments/${id}`)
    .then((res) => {
      dispatch({ type: constants.DELETE_POSTCOMMENTS_SUCCESS, payload: id });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: constants.DELETE_POSTCOMMENTS_FAILURE, payload: err });
    });
};
// response comments
export const getResponseComments = (responseId) => (dispatch) => {
  let source = Axios.CancelToken.source();
  const loadResponseComments = async () => {
    try {
      dispatch({ type: constants.FETCH_RESPONSECOMMENTS_START });
      await axiosWithAuth()
        .get(`api/responses/${responseId}/comments`, {
          cancelToken: source.token,
        })
        .then((res) => {
          dispatch({
            type: constants.FETCH_RESPONSECOMMENTS_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: constants.FETCH_RESPONSECOMMENTS_FAILURE,
            payload: err,
          });
        });
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("request cancelled");
      } else {
        console.log(error);
      }
    }
  };
  loadResponseComments();
  return () => {
    source.cancel();
  };
};

export const addResponseComment = (inputComment, responseId) => (dispatch) => {
  dispatch({ type: constants.ADD_RESPONSECOMMENTS_START });
  axiosWithAuth()
    .post(`api/responses/${responseId}/comments`, inputComment)
    .then((res) => {
      dispatch({
        type: constants.ADD_RESPONSECOMMENTS_SUCCESS,
        payload: inputComment,
      });
    })
    .catch((err) => {
      console.log("add comment error", err);
      dispatch({ type: constants.ADD_RESPONSECOMMENTS_FAILURE, payload: err });
    });
};

export const editResponseComment = (commentToEdit) => (dispatch) => {
  dispatch({ type: constants.EDIT_RESPONSECOMMENTS_START });
  const { comment_body } = commentToEdit;
  const newComment = {
    comment_body: commentToEdit.comment_body,
  };

  axiosWithAuth()
    .put(`api/pollComments/${commentToEdit.id}`, newComment)
    .then((res) => {
      dispatch({
        type: constants.EDIT_RESPONSECOMMENTS_SUCCESS,
        payload: commentToEdit,
      });
    })
    .catch((err) => {
      console.log(err, "unable to edit comment");
      dispatch({ type: constants.EDIT_RESPONSECOMMENTS_FAILURE, payload: err });
    });
};

export const deleteResponseComment = (responseId) => (dispatch) => {
  dispatch({ type: constants.DELETE_RESPONSECOMMENTS_START });
  axiosWithAuth()
    .delete(`api/pollComments/${responseId}`)
    .then((res) => {
      dispatch({
        type: constants.DELETE_RESPONSECOMMENTS_SUCCESS,
        payload: responseId,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: constants.DELETE_RESPONSECOMMENTS_FAILURE,
        payload: err,
      });
    });
};
