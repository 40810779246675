import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUserPosts } from "../../redux/actions";
import { DropdownButton, Dropdown } from "react-bootstrap";
//Components
import ProfilePosts from "./ProfilePosts";

const PostList = (props) => {
  const { getUserPosts } = props;

  useEffect(() => {
    getUserPosts(localStorage.getItem("user_id"));
  }, [props.userPosts.length, getUserPosts]);

  function checkUserPosts() {
    if (!props.userPosts.length) {
      return <h3>Give your opinion on any topic to see it here!</h3>;
    } else {
      return (
        <>
          {props.userPosts.map((post) => {
            return (
              <ProfilePosts
                key={post.id}
                id={post.id}
                vote={post.vote}
                post_body={post.post_body}
                topic_id={post.topic_id}
                likes={post.likes}
                dislikes={post.dislikes}
                created_at={post.created_at}
                user_id={post.user_id}
              />
            );
          })}
        </>
      );
    }
  }

  return (
    <div className="post-list">
      <div className="post-list-header">
        <h3>YOUR POSTS</h3>
        <DropdownButton
          title="All"
          variant="light"
          className="filter-drop-down"
        >
          <Dropdown.Item eventKey="1" active>
            All
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">Most recent</Dropdown.Item>
          <Dropdown.Item eventKey="3">Highest rated</Dropdown.Item>
        </DropdownButton>
      </div>
      {checkUserPosts()}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    userId: serverReducer.userId,
    userPosts: serverReducer.userPosts,
    isFetchingUserPosts: serverReducer.isFetchingUserPosts,
  };
};

export default connect(mapStateToProps, { getUserPosts })(PostList);
