import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
// Components
import StatusCard from "../statuspolls/StatusCard";
import ProfilePostCards from "./ProfilePostCards";
import StatusForm from "../statuspolls/StatusForm";
// import Spinner from '../spinners/spinner';

const ProfilePostList = (props) => {
  function statusFormRender() {
    if (Number(props.homeUserId) === props.profileUserId) {
      return (
        <StatusForm
          setStatusSubmit={props.setStatusSubmit}
          statusSubmit={props.statusSubmit}
          user_name={props.user_name}
          homeUserId={props.homeUserId}
          user_image={props.user_image}
          pollSubmit={props.pollSubmit}
          setPollSubmit={props.setPollSubmit}
        />
      );
    } else {
      return null;
    }
  }

  function postStatusPollCompiler() {
    const profilePosts = props.profilePosts;
    const profileStatuses = props.profileStatuses;
    const combinedPosts = [...profilePosts, ...profileStatuses];
    const sortedCombinedPosts = combinedPosts.sort((a, b) =>
      b.created_at > a.created_at ? 1 : -1
    );
    return sortedCombinedPosts.map((post) => {
      if (post.status_title || post.status_body) {
        return (
          <StatusCard
            setToggleDelete={props.setToggleDelete}
            toggleDelete={props.toggleDelete}
            key={`${post.id}${post.status_title}`}
            id={post.id}
            status_title={post.status_title}
            status_body={post.status_body}
            created_at={post.created_at}
            user_id={post.user_id}
            user_name={props.user_name}
          />
        );
      } else {
        return (
          <ProfilePostCards
            setToggleDelete={props.setToggleDelete}
            toggleDelete={props.toggleDelete}
            key={post.id}
            id={post.id}
            vote={post.vote}
            post_body={post.post_body}
            topic_id={post.topic_id}
            likes={post.likes}
            dislikes={post.dislikes}
            created_at={post.created_at}
            user_id={post.user_id}
            user_name={props.user_name}
          />
        );
      }
    });
  }

  return (
    <div className="post-list">
      {statusFormRender()}
      <div className="post-list-header">
        <h3>{props.user_name.toUpperCase()}'S POSTS</h3>
        <DropdownButton
          title="All"
          variant="light"
          className="filter-drop-down"
        >
          <Dropdown.Item eventKey="1" active>
            All
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">Most recent</Dropdown.Item>
          <Dropdown.Item eventKey="3">Highest rated</Dropdown.Item>
        </DropdownButton>
      </div>
      {!props.profilePosts.length && !props.profileStatuses.length ? (
        <h3>Create a status or post your opinion to see it here!</h3>
      ) : (
        postStatusPollCompiler()
      )}
    </div>
  );
};

export default ProfilePostList;
