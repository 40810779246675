import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import axiosWithAuth from "../../utils/axiosWithAuth";

const FollowersUserItem = (props) => {
  const { followerId, followers } = props;
  const [followItem, setFollowItem] = useState({
    user_name: "",
    photo_url: "",
  });

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const getFollowingNames = async () => {
      let id = followerId;
      try {
        axiosWithAuth()
          .get(`api/users/${id}`, { cancelToken: source.token })
          .then((res) => {
            setFollowItem({
              user_name: res.data.user_name,
              photo_url: res.data.photo_url,
            });
          })
          .catch((err) => console.log(err));
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    getFollowingNames();
    return () => {
      source.cancel();
    };
  }, [followerId, followers]);

  function renderUserImage() {
    if (!followItem.photo_url) {
      return (
        <img src="https://via.placeholder.com/150" alt="follow user pic" />
      );
    } else {
      return <img src={followItem.photo_url} alt="follow user pic" />;
    }
  }

  return (
    <div className="follow-item">
      {renderUserImage()}
      <Link to={`/home/profile/${followItem.user_name}`}>
        {followItem.user_name}
      </Link>
    </div>
  );
};

export default FollowersUserItem;
