import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUserInfo } from "../../redux/actions";
// Icons
import CheckBox from "../../assets/icons/CheckBox";
import XBox from "../../assets/icons/XBox";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import OppositeOpinionCard from "./OppositeOpinionCard";

const OppositeOpinions = (props) => {
  const { vote, topic_id } = props;
  const [showOppositeOpinions, setShowOppositeOpinions] = useState(false);
  const [oppositeOpinions, setOppositeOpinions] = useState([]);
  const [sameOpinions, setSameOpinions] = useState([]);
  const [allOpinions, setAllOpinions] = useState([]);
  const [showOpposite, setShowOpposite] = useState(true);
  const [showSame, setShowSame] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadOppositeOpinionPosts = async () => {
      try {
        await axiosWithAuth()
          .get(`api/topics/${topic_id}/oppositePosts`, {
            cancelToken: source.token,
          })
          .then((res) => {
            setOppositeOpinions(res.data.filter((post) => post.vote !== vote));
            setSameOpinions(res.data.filter((post) => post.vote === vote));
            setAllOpinions(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadOppositeOpinionPosts();
    return () => {
      source.cancel();
    };
  }, [vote, topic_id, id, showOppositeOpinions]);

  function checkForVote(vote) {
    if (vote === true) {
      return <CheckBox style={{ marginRight: "15px" }} />;
    } else {
      return <XBox style={{ marginRight: "15px" }} />;
    }
  }

  function renderOppositeCards() {
    if (oppositeOpinions.length && showOpposite) {
      return (
        <>
          {oppositeOpinions.map((card) => {
            return (
              <div key={card.id} className="opposite-post">
                <OppositeOpinionCard
                  score={card.score}
                  postId={card.id}
                  allLikes={props.allLikes}
                  user={props.user}
                  user_id={card.user_id}
                  post_body={card.post_body}
                  vote={card.vote}
                  created_at={card.created_at}
                />
              </div>
            );
          })}
        </>
      );
    } else if (sameOpinions.length && showSame) {
      return (
        <>
          {sameOpinions.map((card) => {
            return (
              <div key={card.id} className="opposite-post">
                <OppositeOpinionCard
                  score={card.score}
                  postId={card.id}
                  allLikes={props.allLikes}
                  user={props.user}
                  user_id={card.user_id}
                  post_body={card.post_body}
                  vote={card.vote}
                  created_at={card.created_at}
                />
              </div>
            );
          })}
        </>
      );
    } else if (allOpinions.length && showAll) {
      return (
        <>
          {allOpinions.map((card) => {
            return (
              <div key={card.id} className="opposite-post">
                <OppositeOpinionCard
                  score={card.score}
                  postId={card.id}
                  allLikes={props.allLikes}
                  user={props.user}
                  user_id={card.user_id}
                  post_body={card.post_body}
                  vote={card.vote}
                  created_at={card.created_at}
                />
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            Come back later to see some opposing views...
          </h2>
        </>
      );
    }
  }

  function selectOppositeTab() {
    setShowOpposite(true);
    setShowAll(false);
    setShowSame(false);
  }
  function selectSameTab() {
    setShowOpposite(false);
    setShowAll(false);
    setShowSame(true);
  }
  function selectAllTab() {
    setShowOpposite(false);
    setShowAll(true);
    setShowSame(false);
  }

  return (
    <div className="opposite-container">
      <button
        className="opposite-show-button"
        onClick={() =>
          showOppositeOpinions
            ? setShowOppositeOpinions(false)
            : setShowOppositeOpinions(true)
        }
      >
        {!!showOppositeOpinions ? (
          <p>hide discussion</p>
        ) : (
          <p>view discussion</p>
        )}
      </button>
      {!!showOppositeOpinions ? (
        <div className="hide-scroll">
          <div className="my-opinion-box">
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "5px" }}>My opinion:</p>
              <p>{checkForVote(props.vote)}</p>
            </div>
            <p className="opposite-user-post">{props.post_body}</p>
          </div>
          <div className="opinion-tabs">
            <button
              className="opinion-tab"
              style={{ color: showOpposite ? "#FF3781" : "#828282" }}
              onClick={selectOppositeTab}
            >
              Disagree
            </button>
            <button
              className="opinion-tab"
              style={{ color: showSame ? "#FF3781" : "#828282" }}
              onClick={selectSameTab}
            >
              Agree
            </button>
            <button
              className="opinion-tab"
              style={{ color: showAll ? "#FF3781" : "#828282" }}
              onClick={selectAllTab}
            >
              All
            </button>
            <Link to={`/home/post/${props.id}`} className="opposite-view-all">
              View My Opinion{" "}
              <FontAwesomeIcon
                color="#FF3781"
                icon={faExternalLinkAlt}
                size="sm"
              />
            </Link>
          </div>
          {renderOppositeCards()}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {};
};

export default connect(mapStateToProps, { getUserInfo })(OppositeOpinions);
