import React from "react";
// import Moment from 'moment';
//Components
import TrendingTopicsCard from "./TrendingTopicsCard";

const TrendingTopicsList = (props) => {
  // function scoreTrendingPosts() {
  //     const likes = props.allLikes
  //     const posts = props.trendingPosts
  //     const likesDict = {};
  //     const postsDict = {};
  //     for (let i=0; i < likes.length; i++) {
  //         if (likes[i].post_id in likesDict) {
  //             likesDict[likes[i].post_id] += 1
  //         } else {
  //             likesDict[likes[i].post_id] = 0
  //         }
  //     }
  //     for (let j=0; j < posts.length; j++) {
  //         if (posts[j].id in likesDict) {
  //             const post_body = posts[j].post_body
  //             const id = posts[j].id
  //             const created_at = posts[j].created_at
  //             const user_id = posts[j].user_id
  //             const likes = likesDict[posts[j].id]
  //             const end = Moment();
  //             const duration = Moment.duration(end.diff(posts[j].created_at));
  //             const score = (likesDict[posts[j].id]) / ((duration.asHours() + 2)**1.5)
  //             postsDict[posts[j].id] = {score, id, post_body, created_at, user_id, likes}
  //         }
  //     }

  //     let scoredPosts = Object.entries(postsDict).sort((a, b) => (a[1].score < b[1].score) ? 1 : -1).slice(0,6)
  //     const scoredPostsArray = []
  //     for (let k=0; k < scoredPosts.length; k++) {
  //         scoredPostsArray.push(scoredPosts[k][1])
  //     }
  //     return scoredPostsArray
  // }

  return (
    <div className="trend-list">
      <h4>TRENDING TOPICS</h4>
      <br />
      {props.topics.map((trendingTopic) => {
        return (
          <TrendingTopicsCard
            key={trendingTopic.id}
            categories={props.categories}
            topicName={trendingTopic.topic_name}
            categoryId={trendingTopic.category_id}
          />
        );
      })}
    </div>
  );
};

export default TrendingTopicsList;
