import React, { useEffect, useState } from "react";
import Axios from "axios";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Moment from "moment";
import { connect } from "react-redux";
import { getResponseComments } from "../../redux/actions";
// Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CommentIcon from "../../assets/icons/CommentIcon";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResponseCard = (props) => {
  const [responseUser, setResponseUser] = useState({});
  const [optionDesc, setOptionDesc] = useState("");
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [commentUser, setCommentUser] = useState("");
  const [showThread, setShowThread] = useState(false);
  const { response, getResponseComments, options, id } = props;

  // get user info for response
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadResponseUser = async () => {
      try {
        await axiosWithAuth()
          .get(`api/users/${response.user_id}`, { cancelToken: source.token })
          .then((res) => {
            setResponseUser(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadResponseUser();
    return () => {
      source.cancel();
    };
  }, [response]);

  useEffect(() => {
    props.getResponseComments(response.id);
  }, [response]);

  function renderUserImage() {
    if (responseUser.photo_url) {
      return (
        <img
          className="response-user-image"
          src={responseUser.photo_url}
          alt="profile-image"
        />
      );
    } else {
      return (
        <img
          className="response-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    }
  }

  useEffect(() => {
    function renderUserOption() {
      const userSelection = options.filter(
        (option) => option.id === response.option_id
      );
      const option = userSelection[0];
      const optionDesc = option.option_desc;
      setOptionDesc(optionDesc);
    }
    renderUserOption();
  }, []);

  function threadLength() {
    const filteredComments = props.responseComments.filter(
      (comment) => comment.parent_id === id
    );
    return filteredComments.length;
  }

  return (
    <div className="response-card-container">
      <div className="response-card-user">
        {renderUserImage()}
        <h3>
          {responseUser.user_name}'s choice:{" "}
          <span style={{ color: "#FF3781" }}>{optionDesc}</span>
        </h3>
      </div>
      <div className="response-card-body">
        <p>{props.response.response_body}</p>
      </div>
      <div className="comment-bottom-container">
        <p className="comment-user-log">
          Posted {Moment(props.created_at).calendar()}
        </p>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>reply</Tooltip>}
        >
          <button
            className="comment-button-style"
            onClick={() =>
              showReplyForm ? setShowReplyForm(false) : setShowReplyForm(true)
            }
          >
            <FontAwesomeIcon icon={faReply} size="1x" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view thread</Tooltip>}
        >
          <button
            className="comment-button-style"
            onClick={() =>
              showThread ? setShowThread(false) : setShowThread(true)
            }
          >
            <CommentIcon /> {threadLength()}
          </button>
        </OverlayTrigger>
      </div>
    </div>
  );
};
const mapStateToProps = ({ serverReducer }) => {
  return {
    responseComments: serverReducer.responseComments,
    isFetchingResponseComments: serverReducer.isFetchingResponseComments,
  };
};

export default connect(mapStateToProps, { getResponseComments })(ResponseCard);
