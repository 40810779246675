import React, { useState, useEffect } from "react";
import { withFormik, Form, Field } from "formik";
import * as yup from "yup";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Spinner from "../spinners/spinner";

const Login = ({
  touched,
  setShowRegister,
  errors,
  status,
  isLoading,
  showError,
  errorMessage,
}) => {
  const [credentials, setCredentials] = useState([]);
  useEffect(() => {
    status && setCredentials((credentials) => [...credentials, status]);
  }, [status]);

  return (
    <div className="login-box">
      <Form className="login-form">
        <label
          style={{
            fontWeight: "bold",
            color: "#334550",
            fontSize: "2rem",
            padding: "2%",
          }}
        >
          Login
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Field
            className="login-field"
            id="email"
            type="text"
            placeholder="email"
            name="email"
          />
          {touched.email && errors.email && (
            <p className="error">{errors.email}</p>
          )}
          <Field
            className="login-field"
            id="password"
            type="password"
            placeholder="password"
            name="password"
          />
        </div>
        {touched.password && errors.password && (
          <p className="error">{errors.password}</p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <button type="submit" className="login-button">
            {!!isLoading ? (
              <Spinner color="#fff" width="14" height="14" />
            ) : (
              "Let's Go!"
            )}
          </button>
          {!!showError && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#EB5757",
                fontSize: "10px",
                fontWeigh: "bold",
              }}
            >
              {errorMessage}
            </p>
          )}
          <button
            style={{ background: "none", border: "none", color: "#334550" }}
            onClick={() => setShowRegister(true)}
          >
            Not a member? Click to join!
          </button>
        </div>
      </Form>
    </div>
  );
};

const FormikLogin = withFormik({
  mapPropsToValues({ email, password }) {
    return {
      email: email || "",
      password: password || "",
    };
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("*must be a valid email")
      .required("*must include an email"),
    password: yup
      .string()
      .min(8, "Passwords must be at least 8 characters long.")
      .max(16, "Passwords must be less than 16 characters long.")
      // .matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$")
      .required("password is required"),
  }),
  handleSubmit(values, { resetForm, setStatus, setErrors, props }) {
    props.setIsLoading(true);
    props.setShowError(false);
    if (!values.email) {
      setErrors({ email: "email does not exist!" });
    } else {
      axiosWithAuth()
        .post("api/auth/login", values)
        .then((res) => {
          props.setIsLoading(false);
          setStatus(res.data);
          localStorage.setItem("user_id", res.data.id);
          localStorage.setItem("token", res.data.token);
          resetForm();
          props.history.push(`/home/recommended`);
        })
        .catch((err) => {
          console.log(err);
          setStatus(false);
          props.setIsLoading(false);
          props.setShowError(true);
          props.setErrorMessage("* username or password is invalid");
        });
    }
  },
})(Login);

export default FormikLogin;
