import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
import { Link } from "react-router-dom";
import Moment from "moment";
import UserPostCommentForm from "./UserPostCommentForm";
//Icons
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Dropdown } from "react-bootstrap";
import CheckBox from "../../assets/icons/CheckBox";
import XBox from "../../assets/icons/XBox";
import UpVote from "../../assets/icons/UpVote";
import DownVote from "../../assets/icons/DownVote";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PostCard = (props) => {
  const userId = localStorage.getItem("user_id");
  const [topicName, setTopicName] = useState("");
  const [postUser, setPostUser] = useState({
    user_name: "",
    photo_url: "",
  });
  const { post, commentSubmit, setCommentSubmit } = props;

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUser = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/users/${post.user_id}`, { cancelToken: source.token })
          .then((res) => {
            setPostUser(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUser();
    return () => {
      source.cancel();
    };
  }, [post.id, post.user_id]);

  // // get topic name
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadTopic = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/topics/${post.topic_id}`, { cancelToken: source.token })
          .then((res) => {
            setTopicName(res.data.topic_name);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadTopic();
    return () => {
      source.cancel();
    };
  }, [post]);

  const likePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${post.id}/likes`, {
        user_name: props.user_name,
      })
      .then((res) => {
        props.setLikes(props.likes + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const disLikePost = () => {
    axiosWithAuth()
      .post(`/api/posts/${post.id}/dislikes`, {
        user_name: props.user_name,
      })
      .then((res) => {
        props.setDislikes(props.dislikes - 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function Vote() {
    if (!post.vote) {
      return (
        <div className="vote-status">
          <XBox />
        </div>
      );
    } else {
      return (
        <div className="vote-status">
          <CheckBox />
        </div>
      );
    }
  }

  function UpVotePercentage() {
    const totalVotes = props.likes + props.dislikes;
    const voteRatio = Math.ceil((props.likes / totalVotes) * 100);
    if (!props.likes) {
      return <span>0%</span>;
    } else {
      return <span>{voteRatio}%</span>;
    }
  }

  function DownVotePercentage() {
    const totalVotes = props.likes + props.dislikes;
    const voteRatio = Math.floor((props.dislikes / totalVotes) * 100);
    if (!props.dislikes) {
      return <span>0%</span>;
    } else {
      return <span>{voteRatio}%</span>;
    }
  }

  function renderUserImage() {
    if (!postUser.photo_url) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="post-user-image"
          src={postUser.photo_url}
          alt="user profile"
        />
      );
    }
  }

  function deletePost() {
    axiosWithAuth()
      .delete(`/api/posts/${post.id}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkIfUserShowOptions() {
    if (post.user_id === Number(userId)) {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu size="sm">
            <button
              style={{ background: "none", border: "none", outline: "none" }}
              onClick={deletePost}
            >
              delete
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <button
              style={{ background: "none", border: "none", outline: "none" }}
              to={"#/action-1"}
            >
              report{" "}
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  function renderUpVoteButton() {
    if (props.isLiked) {
      return (
        <button onClick={null} className="liked-button-style">
          <UpVote />
        </button>
      );
    } else {
      return (
        <button onClick={likePost} className="like-button-style">
          <UpVote />
        </button>
      );
    }
  }
  function renderDownVoteButton() {
    if (props.isDisliked) {
      return (
        <button onClick={null} className="disliked-button-style">
          <DownVote />
        </button>
      );
    } else {
      return (
        <button onClick={disLikePost} className="dislike-button-style">
          <DownVote />
        </button>
      );
    }
  }

  return (
    <>
      <div className="post-card" key={post.id}>
        {Vote()}
        <h3>{topicName}</h3>
        <Link
          style={{ textDecoration: "none" }}
          key={post.id}
          to={`/home/post/${post.id}`}
        >
          <div className="post-interior-container">
            {renderUserImage()}
            <p className="post-body">
              <Link to={`/home/profile/${postUser.user_name}`}>
                {postUser.user_name}
              </Link>
              <br />
              {post.post_body}
            </p>
          </div>
        </Link>
        <p className="post-user-log">
          Posted {Moment(post.created_at).calendar()}
        </p>
        <div className="post-icon-container">
          {UpVotePercentage()}
          {renderUpVoteButton()}
          {DownVotePercentage()}
          {renderDownVoteButton()}
          <button className="share-button-style">
            <FontAwesomeIcon icon={faTwitter} size="sm" />
            <p style={{ fontSize: "8px" }}>share</p>
          </button>
          <button className="share-button-style">
            <FontAwesomeIcon icon={faFacebook} size="sm" />
            <p style={{ fontSize: "8px" }}>share</p>
          </button>
          {checkIfUserShowOptions()}
        </div>
      </div>
      <UserPostCommentForm
        postId={post.id}
        user_image={props.user_image}
        commentSubmit={commentSubmit}
        setCommentSubmit={setCommentSubmit}
      />
    </>
  );
};

export default PostCard;
