import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserInfo, getPollByPollId } from "../../redux/actions";
import Moment from "moment";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
//Components
import UserPollOpinion from "./UserPollOpinion";
//Icons
import CommentIcon from "../../assets/icons/CommentIcon";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PollCard = (props) => {
  const userId = localStorage.getItem("user_id");
  const [userImage, setUserImage] = useState("https://via.placeholder.com/150");
  const [pollAuthor, setPollAuthor] = useState({});
  const [singlePoll, setSinglePoll] = useState({});
  const [voteSubmitted, setVoteSubmitted] = useState(false);
  const { pollUserId, pollId } = props;

  // get user image
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUserImage = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/users/${pollUserId}`, { cancelToken: source.token })
          .then((res) => {
            setUserImage(res.data.photo_url);
            setPollAuthor(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUserImage();
    return () => {
      source.cancel();
    };
  }, [pollUserId]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadSinglePoll = async () => {
      try {
        await axiosWithAuth()
          .get(`api/polls/${pollId}`, { cancelToken: source.token })
          .then((res) => {
            setSinglePoll(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadSinglePoll();
    return () => {
      source.cancel();
    };
  }, [voteSubmitted, pollId]);

  function deletePoll() {
    axiosWithAuth()
      .delete(`/api/polls/${pollId}`)
      .then(
        props.toggleDelete
          ? props.setToggleDelete(false)
          : props.setToggleDelete(true)
      )
      .catch((err) => {
        console.log(err);
      });
  }

  function renderUserImage() {
    if (!userImage) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img className="post-user-image" src={userImage} alt="user profile" />
      );
    }
  }

  function checkIfUserShowOptions() {
    if (pollUserId === Number(userId)) {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu size="sm">
            <button
              style={{ background: "none", border: "none", outline: "none" }}
              onClick={deletePoll}
            >
              delete
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div
              style={{ background: "none", border: "none", outline: "none" }}
              to={"#/action-1"}
            >
              report
            </div>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  return (
    <div className="post-card" style={{ padding: "1% 2% 2% 1%" }}>
      <Link style={{ textDecoration: "none" }} to={`/home/poll/${pollId}`}>
        <div className="poll-interior-container">
          {renderUserImage()}
          <p className="post-body">
            <span style={{ fontWeight: "bold" }}>{pollAuthor.user_name}</span>
            <br />
            {props.pollTitle}
          </p>
        </div>
      </Link>
      <h3 className="poll-body">{props.pollBody}</h3>
      <UserPollOpinion
        key={pollId}
        setVoteSubmitted={setVoteSubmitted}
        pollId={props.pollId}
        singlePoll={singlePoll}
        isFetchingSinglePoll={props.isFetchingSinglePoll}
      />
      <p className="post-user-log">
        Posted {Moment(props.createdAt).calendar()}
      </p>
      <div className="post-icon-container">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view discussion</Tooltip>}
        >
          <Link to={`/home/poll/${pollId}`} className="button-style">
            <CommentIcon />
          </Link>
        </OverlayTrigger>
        {checkIfUserShowOptions()}
      </div>
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    userId: serverReducer.userId,
    singlePoll: serverReducer.singlePoll,
    isFetchingSinglePoll: serverReducer.isFetchingSinglePoll,
  };
};

export default connect(mapStateToProps, { getUserInfo, getPollByPollId })(
  PollCard
);
