import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";

const PollResponseForm = (props) => {
  const [showResponseForm, setShowResponseForm] = useState(false);
  const [inputVote, setInputVote] = useState({
    option_id: props.option.id,
    user_id: props.homeUserId,
    response_body: "",
  });

  const handleChange = (e) => {
    setInputVote({ ...inputVote, [e.target.name]: e.target.value });
  };
  const voteSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`api/polls/${props.pollId}/responses`, inputVote)
      .then((res) => {
        console.log(res);
        props.setVoteSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="response-form-container">
      <div
        className="response-option-button"
        onClick={() =>
          showResponseForm
            ? setShowResponseForm(false)
            : setShowResponseForm(true)
        }
      >
        {props.option.option_desc}
      </div>
      {!!showResponseForm ? (
        <form className="response-form" onSubmit={voteSubmit}>
          <textarea
            className="response-form-input"
            type="text"
            placeholder="What do you think?"
            onChange={handleChange}
            name="response_body"
            value={inputVote.response_body}
            maxLength="280"
            spellCheck={true}
          />
          <button className="response-form-submit">vote</button>
        </form>
      ) : null}
    </div>
  );
};

export default PollResponseForm;
