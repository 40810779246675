import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
// import 'emoji-mart/css/emoji-mart.css';
// import data from 'emoji-mart/data/twitter.json'
// import { Picker } from 'emoji-mart';
import { faSmile, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UserPostCommentForm(props) {
  // const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const { commentSubmit, setCommentSubmit } = props;
  const [inputComment, setInputComment] = useState({
    comment_body: "",
    user_id: localStorage.getItem("user_id"),
  });

  // const addEmoji = e => {
  //     let emoji = e.native;
  //     setInputComment({
  //         comment_body: inputComment.comment_body + emoji
  //     });
  // };

  const handleChange = (e) => {
    setInputComment({ ...inputComment, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`api/posts/${props.postId}/comments`, inputComment)
      .then((res) => {
        console.log("add post comment response", res);
        setInputComment({
          comment_body: "",
        });
        !commentSubmit ? setCommentSubmit(true) : setCommentSubmit(false);
      })
      .catch((err) => {
        console.log("add comment error", err);
      });
  };

  function renderUserImage() {
    if (!props.user_image) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="post-user-image"
          src={props.user_image}
          alt="user profile"
        />
      );
    }
  }

  return (
    <div className="comment-form-container">
      <span>{renderUserImage()}</span>
      <Form className="comment-form" onSubmit={handleSubmit}>
        <textarea
          className="comment-form-text"
          placeholder="what do you think?"
          onChange={handleChange}
          name="comment_body"
          value={inputComment.comment_body}
          maxLength="800"
          spellCheck={true}
          required
        />
        <div className="comment-form-footer">
          {/* <span className='comment-icon-button' onClick={() => !showEmojiPicker ? setShowEmojiPicker(true) : setShowEmojiPicker(false)}>
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id={`tool-tip-top`}>
                                    add emoji
                                </Tooltip>
                            }
                        >
                            <FontAwesomeIcon icon={faSmile} size='lg' />
                        </OverlayTrigger>
                    </span> */}
          <span className="comment-icon-button">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id={`tool-tip-top`}>coming soon!</Tooltip>}
            >
              <FontAwesomeIcon icon={faImage} size="lg" />
            </OverlayTrigger>
          </span>
          <button className="comment-form-button">submit</button>
        </div>
      </Form>
      {/* {
                !!showEmojiPicker ? 
                    <div className='emoji-picker'>
                        <Picker 
                            set='twitter'
                            data={data} 
                            title='select emoji!'
                            emoji='point_up'
                            onSelect={addEmoji} 
                        />
                    </div> : <span></span>
            } */}
    </div>
  );
}
