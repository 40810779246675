import React from "react";
import TrendList from "./TrendList";
import TrendingTopicsList from "./TrendingTopicsList";

const TrendingPage = (props) => {
  return (
    <div className="trending-page">
      <TrendList
        allLikes={props.allLikes}
        trendingPosts={props.trendingPosts}
      />
      <TrendingTopicsList topics={props.topics} categories={props.categories} />
    </div>
  );
};

export default TrendingPage;
