import React from "react";
import { Link } from "react-router-dom";

const WelcomeNav = () => {
  return (
    <div className="welcome-nav-bar">
      <div className="welcome-nav-bar-icons">
        <h2>vennthink</h2>
        <div className="welcome-nav-link-container">
          <Link className="nav-links" to={"/"}>
            login
          </Link>
          <Link className="nav-links" to={"/howitworks"}>
            how it works
          </Link>
          <Link className="nav-links" to={"/about"}>
            our mission
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomeNav;
