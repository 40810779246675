import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import { connect } from "react-redux";
import { getUserInfo } from "../../redux/actions";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
// Icons
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CommentIcon from "../../assets/icons/CommentIcon";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import CommentThread from "./comments/CommentThread";
import CommentReply from "./comments/CommentReply";

const CommentCard = (props) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [commentUser, setCommentUser] = useState("");
  const [showThread, setShowThread] = useState(false);
  const { user_id, threadComments, id } = props;

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadUser = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/users/${user_id}`, { cancelToken: source.token })
          .then((res) => {
            setCommentUser(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadUser();
    return () => {
      source.cancel();
    };
  }, [props.id, user_id]);

  function renderUserImage() {
    if (!commentUser.photo_url) {
      return (
        <img
          className="comment-user-image"
          src="https://via.placeholder.com/150"
          alt="post user pic"
        />
      );
    } else {
      return (
        <img
          className="comment-user-image"
          src={commentUser.photo_url}
          alt="post user pic"
        />
      );
    }
  }

  function threadLength() {
    const filteredComments = threadComments.filter(
      (comment) => comment.parent_id === id
    );
    return filteredComments.length;
  }

  return (
    <div className="comment-card">
      <div className="comment-interior-container">
        {renderUserImage()}
        <p className="comment-body">
          <span style={{ fontWeight: "bold" }}>
            <Link to={`/home/profile/${commentUser.user_name}`}>
              {commentUser.user_name}
            </Link>
          </span>
          <br />
          {props.comment_body}
        </p>
      </div>
      <div className="comment-bottom-container">
        <p className="comment-user-log">
          Posted {Moment(props.created_at).calendar()}
        </p>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>reply</Tooltip>}
        >
          <button
            className="comment-button-style"
            onClick={() =>
              showReplyForm ? setShowReplyForm(false) : setShowReplyForm(true)
            }
          >
            <FontAwesomeIcon icon={faReply} size="1x" />
          </button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view thread</Tooltip>}
        >
          <button
            className="comment-button-style"
            onClick={() =>
              showThread ? setShowThread(false) : setShowThread(true)
            }
          >
            <CommentIcon /> {threadLength()}
          </button>
        </OverlayTrigger>
      </div>
      {!showReplyForm ? null : (
        <CommentReply
          commentId={props.id}
          postId={props.post_id}
          replySubmit={props.replySubmit}
          showReplyForm={showReplyForm}
          setReplySubmit={props.setReplySubmit}
          setShowReplyForm={setShowReplyForm}
        />
      )}
      {!showThread ? null : (
        <CommentThread
          commentId={props.id}
          threadComments={threadComments}
          replySubmit={props.replySubmit}
          setReplySubmit={props.setReplySubmit}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
  };
};

export default connect(mapStateToProps, { getUserInfo })(CommentCard);
