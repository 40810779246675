import React from "react";
import Moment from "moment";
import { LineChart, Line, XAxis, Tooltip, Legend } from "recharts";

const Calendar = (props) => {
  function contributionData() {
    var months = {
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
    };
    // const startDate = props.userPosts.map(post => Moment(post.created_at).subtract(30, 'days').format('MMM DD'))
    const allData = props.userPosts.map((post) =>
      Moment(post.created_at).format("MMM")
    );
    for (let i = 0; i < allData.length; i++) {
      if (months.hasOwnProperty(allData[i])) {
        months[allData[i]] += 1;
      } else {
        months[allData[i]] = 1;
      }
    }
    const newData = [];
    for (var month in months) {
      newData.push({ month });
    }
    const finalArray = newData.map((mon) => ({
      ...mon,
      posts: months[mon.month],
    }));
    return finalArray;
  }

  return (
    <LineChart
      width={350}
      height={160}
      data={contributionData()}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis
        dataKey="month"
        wrapperStyle={{
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'sans-serif'",
          fontWeight: "bold",
        }}
      />
      <Tooltip
        wrapperStyle={{
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'sans-serif'",
          fontWeight: "bold",
        }}
      />
      <Legend
        wrapperStyle={{
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'sans-serif'",
          fontWeight: "bold",
        }}
      />
      <Line type="monotone" dataKey="posts" stroke="#FF3781" />
    </LineChart>
  );
};

export default Calendar;
