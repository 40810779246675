import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { history } from "react-router-dom";
import { Modal } from "react-bootstrap";

const PollForm = (props) => {
  const [inputPoll, setInputPoll] = useState({
    poll_title: "",
    poll_body: "",
    // starts_at: '',
    // ends_at: '',
  });

  const [inputPollOptionOne, setInputPollOptionOne] = useState({
    option_desc: "",
    poll_id: "",
  });
  const [inputPollOptionTwo, setInputPollOptionTwo] = useState({
    option_desc: "",
    poll_id: "",
  });
  const [inputPollOptionThree, setInputPollOptionThree] = useState({
    option_desc: "",
    poll_id: "",
  });
  const [inputPollOptionFour, setInputPollOptionFour] = useState({
    option_desc: "",
    poll_id: "",
  });

  const handlePollChange = (e) => {
    setInputPoll({ ...inputPoll, [e.target.name]: e.target.value });
  };
  const handleOptionChangeOne = (e) => {
    setInputPollOptionOne({
      ...inputPollOptionOne,
      [e.target.name]: e.target.value,
    });
  };
  const handleOptionChangeTwo = (e) => {
    setInputPollOptionTwo({
      ...inputPollOptionTwo,
      [e.target.name]: e.target.value,
    });
  };
  const handleOptionChangeThree = (e) => {
    setInputPollOptionThree({
      ...inputPollOptionThree,
      [e.target.name]: e.target.value,
    });
  };
  const handleOptionChangeFour = (e) => {
    setInputPollOptionFour({
      ...inputPollOptionFour,
      [e.target.name]: e.target.value,
    });
  };

  function combineInputs() {
    const optionArray = [];
    if (inputPollOptionOne.option_desc.length > 0) {
      optionArray.push(inputPollOptionOne);
    }
    if (inputPollOptionTwo.option_desc.length > 0) {
      optionArray.push(inputPollOptionTwo);
    }
    if (inputPollOptionThree.option_desc.length > 0) {
      optionArray.push(inputPollOptionThree);
    }
    if (inputPollOptionFour.option_desc.length > 0) {
      optionArray.push(inputPollOptionFour);
    }
    return optionArray;
  }

  const handlePollSubmit = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .post(`api/users/${props.homeUserId}/polls`, inputPoll)
      .then((res) => {
        const inputsWithPollIds = combineInputs();
        inputsWithPollIds.forEach((option) => {
          axiosWithAuth()
            .post(`api/polls/${res.data.id}/options`, option)
            .then((res) => {
              !props.pollSubmit && props.showPollForm
                ? props.setPollSubmit(true) && props.setShowPollForm(false)
                : props.setPollSubmit(false) && props.setShowPollForm(false);
            })
            .then(props.setShowPollForm(false))
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontFamily:
              "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif",
          }}
          id="contained-modal-title-vcenter"
        >
          Create your own poll
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="poll-form" onSubmit={handlePollSubmit}>
          <input
            className="poll-input"
            placeholder="What's your question?"
            onChange={handlePollChange}
            name="poll_title"
            value={inputPoll.poll_title}
            maxLength="280"
            spellCheck={true}
            required
          />
          <input
            className="poll-input"
            placeholder="Any additional thoughts or context?"
            onChange={handlePollChange}
            name="poll_body"
            value={inputPoll.poll_body}
            maxLength="500"
            spellCheck={true}
            required
          />
          <input
            className="poll-input"
            placeholder="Option *required*"
            onChange={handleOptionChangeOne}
            name="option_desc"
            value={inputPollOptionOne.option_desc}
            maxLength="30"
            spellCheck={true}
            required
          />
          <input
            className="poll-input"
            placeholder="Option *required*"
            onChange={handleOptionChangeTwo}
            name="option_desc"
            value={inputPollOptionTwo.option_desc}
            maxLength="30"
            spellCheck={true}
            required
          />
          <input
            className="poll-input"
            placeholder="Option"
            onChange={handleOptionChangeThree}
            name="option_desc"
            value={inputPollOptionThree.option_desc}
            maxLength="30"
            spellCheck={true}
          />
          <input
            className="poll-input"
            placeholder="Option"
            onChange={handleOptionChangeFour}
            name="option_desc"
            value={inputPollOptionFour.option_desc}
            maxLength="30"
            spellCheck={true}
          />
          <button className="poll-submit-button">submit</button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PollForm;
