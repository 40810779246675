import React from "react";
import { Modal } from "react-bootstrap";
import FollowingUserItem from "./FollowingUserItem";

const FollowingModal = (props) => {
  const { followingShow, setFollowingShow, following } = props;
  return (
    <div>
      <Modal
        size="md"
        show={followingShow}
        onHide={() => setFollowingShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Following</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {following.map((follower) => (
            <FollowingUserItem
              key={follower.id}
              followingId={follower.following_id}
              following={following}
            />
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FollowingModal;
