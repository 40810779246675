import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../redux/actions";
import Moment from "moment";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import axiosWithAuth from "../../utils/axiosWithAuth";
//Icons
import CommentIcon from "../../assets/icons/CommentIcon";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SourceCard = (props) => {
  const [userImage, setUserImage] = useState("https://via.placeholder.com/150");
  const [sourceAuthor, setSourceAuthor] = useState({});
  const {
    sourceUserId,
    sourceId,
    homeUserId,
    sourceName,
    sourceBody,
    createdAt,
    sourceUrl,
  } = props;

  // get user image
  useEffect(() => {
    axiosWithAuth()
      .get(`/api/users/${sourceUserId}`)
      .then((res) => {
        setUserImage(res.data.photo_url);
        setSourceAuthor(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sourceUserId]);

  function deleteSource() {
    axiosWithAuth()
      .delete(`/api/sources/${sourceId}`)
      .then(
        props.toggleDelete
          ? props.setToggleDelete(false)
          : props.setToggleDelete(true)
      )
      .catch((err) => {
        console.log(err);
      });
  }

  function renderUserImage() {
    if (!userImage) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img className="post-user-image" src={userImage} alt="user profile" />
      );
    }
  }

  function checkIfUserShowOptions() {
    if (sourceUserId === Number(homeUserId)) {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu size="sm">
            <button
              style={{ background: "none", border: "none", outline: "none" }}
              onClick={deleteSource}
            >
              delete
            </button>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown>
          <Dropdown.Toggle
            style={{
              background: "none",
              border: "none",
              outline: "none",
              display: "flex",
            }}
          >
            <div className="post-options-button">
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div
              style={{ background: "none", border: "none", outline: "none" }}
              to={"#/action-1"}
            >
              report
            </div>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  return (
    <div className="post-card" style={{ padding: "1% 2% 2% 1%" }}>
      <Link style={{ textDecoration: "none" }} to={`/home/sources/${sourceId}`}>
        <div className="poll-interior-container">
          {renderUserImage()}
          <p className="post-body">
            <span style={{ fontWeight: "bold" }}>{sourceAuthor.user_name}</span>
            <br />
            {sourceName}
          </p>
        </div>
      </Link>
      <a
        href={`${sourceUrl}`}
        target="_blank"
        rel="noopener noreferrer"
        className="poll-body"
      >
        {sourceUrl}
      </a>
      <div
        style={{
          padding: "2%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {sourceBody}
        </p>
      </div>
      <div className="post-icon-container">
        <p className="post-user-log">Posted {Moment(createdAt).calendar()}</p>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tool-tip-top`}>view discussion</Tooltip>}
        >
          <Link to={`/home/source/${sourceId}`} className="button-style">
            <CommentIcon />
          </Link>
        </OverlayTrigger>
        {checkIfUserShowOptions()}
      </div>
    </div>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    userId: serverReducer.userId,
  };
};

export default connect(mapStateToProps, { getUserInfo })(SourceCard);
