import React from "react";
import { Modal } from "react-bootstrap";
// Components
import FollowersUserItem from "./FollowersUserItem";

const FollowersModal = (props) => {
  const { followersShow, setFollowersShow, followers } = props;
  return (
    <div>
      <Modal
        size="md"
        show={followersShow}
        onHide={() => setFollowersShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Followers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {followers.map((follower) => (
            <FollowersUserItem
              key={follower.id}
              followerId={follower.user_id}
              followers={followers}
            />
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FollowersModal;
