import React from "react";
import venn1 from "./assets/imgs/venn1.png";
import venn2 from "./assets/imgs/venn2.png";
import venn3 from "./assets/imgs/venn3.png";

const HowItWorks = () => {
  return (
    <div className="how-it-works-container">
      <h2 className="how-it-works-header">
        <span className="header-span">H</span>
        <span className="header-span">o</span>
        <span className="header-span">w</span>
        <span> </span>
        <span className="header-span">i</span>
        <span className="header-span">t</span>
        <span> </span>
        <span className="header-span">W</span>
        <span className="header-span">o</span>
        <span className="header-span">r</span>
        <span className="header-span">k</span>
        <span className="header-span">s</span>
      </h2>
      <section className="how-it-works-section-1">
        <div className="step-section">
          <div className="topic-container">
            <h3 className="topic-header">Choose a Topic</h3>
            <p className="topic-body">
              Thousands of daily news stories are distilled down to only a a few
              hand-picked topics per day.
            </p>
          </div>
          <div className="how-it-works-demo">
            <img src={venn1} alt="demo" />
          </div>
        </div>
        <div className="step-section">
          <div className="topic-container">
            <h3 className="topic-header">Research</h3>
            <p className="topic-body">
              There's no rush to respond. Vennthink limits the volume of topics
              that appear every day, so you have time to research and carefully
              consider both sides.
            </p>
          </div>
          <div className="how-it-works-demo">
            <img src={venn2} alt="demo" />
          </div>
        </div>
        <div className="step-section">
          <div className="topic-container">
            <h3 className="topic-header">Write Your Opinion</h3>
            <p className="topic-body">
              Craft your response with those who may disagree with you in mind.
              Avoid logical fallacies, avoid condescension, and remember - you
              have plenty of space to form a nuanced opinion.
            </p>
          </div>
          <div className="how-it-works-demo">
            <img src={venn3} alt="demo" />
          </div>
        </div>
      </section>
      <section className="how-it-works-section-2">
        <div className="step-section">
          <h3 className="topic-header">
            Better understand those who disagree with you
          </h3>
          <p className="topic-body">
            Not seeing a topic that interests you? Create your own poll and
            interact with your followers.
          </p>
          {/* <p className='topic-body'>Review as many as you'd like, and vote them up or down based on the following
                        criteria:
                        <ul className='topic-list'>
                            <li className='topic-list-item'>Is the opinion logically consistent?</li>
                            <li className='topic-list-item'>Is evidence used and cited to support their opinion?</li>
                            <li className='topic-list-item'>Is the tone respectful and are they attempting to appeal to your side of the argument?</li>
                        </ul>
                    </p>
                    <p className='topic-body'>Remember, at this stage, you disagree with EVERYONE, so consider the effectiveness <br/>of the 
                        arguments presented to you and judge fairly. Your fellow thinkers will return the favor.
                    </p> */}
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
