import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Moment from "moment";
import { Link } from "react-router-dom";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TrendCard = (props) => {
  const [userImage, setUserImage] = useState("https://via.placeholder.com/150");
  const [userHandle, setUserHandle] = useState(null);
  const { user_id } = props;

  // get user image
  useEffect(() => {
    axiosWithAuth()
      .get(`/api/users/${user_id}`)
      .then((res) => {
        setUserImage(res.data.photo_url);
        setUserHandle(res.data.user_name);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setUserImage, user_id]);

  function renderUserImage() {
    if (!userImage) {
      return (
        <img
          className="trend-post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile"
        />
      );
    } else {
      return (
        <img
          className="trend-post-user-image"
          src={userImage}
          alt="user profile"
        />
      );
    }
  }

  return (
    <div>
      <div className="trend-card">
        <div className="trend-post-interior-container">
          {renderUserImage()}
          <span className="trend-post-handle">
            <Link to={`/home/profile/${userHandle}`}>{userHandle}</Link>
          </span>
          <p className="trend-post-body">{props.post_body}</p>
        </div>
        <div className="trend-footer">
          <p className="trend-post-log">
            Posted {Moment(props.created_at).calendar()}
          </p>
          <span className="trending-status">
            <h4 style={{ color: "#828282" }}>{props.likes}</h4>
            <FontAwesomeIcon
              icon={faLongArrowAltUp}
              size="1x"
              style={{ color: "#6FCF97" }}
            />
          </span>
          <Link className="trend-chevron-right" to={`/home/post/${props.id}`}>
            view
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrendCard;
