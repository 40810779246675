import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosWithAuth from "../../utils/axiosWithAuth";
//Icons
import SearchIcon from "../../assets/icons/SearchIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Search = () => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axiosWithAuth()
      .get("/api/users")
      .then((res) => {
        setUsers(res.data.map((person) => person.user_name));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    const results = users.filter((user) =>
      user.toLowerCase().includes(searchInput)
    );
    setSearchResults(results);
  }, [searchInput, users]);

  function showSearchResults() {
    if (searchInput === "") {
      return <span></span>;
    } else {
      return (
        <ul className="search-results-container">
          {searchResults.map((item) => (
            <li className="search-result-item">
              <Link to={`/home/profile/${item}`}>{item}</Link>
            </li>
          ))}
        </ul>
      );
    }
  }

  return (
    <div className="search-container">
      {/* <div className='tablet-search-icon'>
                <button style={{background:'none',border:'none'}}><SearchIcon className='search-icon' /></button>
            </div> */}
      <form className="search-form">
        <SearchIcon className="search-icon" />
        <input
          className="search-input"
          placeholder="Search"
          value={searchInput}
          onChange={handleChange}
        />
        {!!searchInput.length > 0 ? (
          <button
            className="search-cancel-button"
            onClick={() => setSearchInput("")}
          >
            <FontAwesomeIcon icon={faTimesCircle} size="1x" />
          </button>
        ) : null}
        {showSearchResults()}
      </form>
    </div>
  );
};

export default Search;
