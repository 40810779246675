import React, { useState, useEffect } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import Axios from "axios";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getUserInfo, getSinglePost } from "../../redux/actions";
import CommunityOpinion from "../communityopinion/CommunityOpinion";
import PostCard from "./PostCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SinglePost = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const { getSinglePost, commentSubmit, setCommentSubmit } = props;

  useEffect(() => {
    getSinglePost(id);
  }, [id, getSinglePost]);

  // Get likes
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadLikes = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/posts/${id}/likes`, { cancelToken: source.token })
          .then((res) => {
            setLikes(res.data.length);
            const findLike = res.data.filter(
              (like) => like.user_name === props.user.user_name
            );
            if (findLike.length) {
              setIsLiked(true);
            } else {
              setIsLiked(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadLikes();
    return () => {
      source.cancel();
    };
  }, [likes, dislikes, id, props.user.user_name]);

  // Get dislikes
  useEffect(() => {
    let source = Axios.CancelToken.source();
    const loadDislikes = async () => {
      try {
        await axiosWithAuth()
          .get(`/api/posts/${id}/dislikes`, { cancelToken: source.token })
          .then((res) => {
            setDislikes(res.data.length);
            const findDislike = res.data.filter(
              (dislike) => dislike.user_name === props.user.user_name
            );
            if (findDislike.length) {
              setIsDisliked(true);
            } else {
              setIsDisliked(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log("request cancelled");
        } else {
          console.log(error);
        }
      }
    };
    loadDislikes();
    return () => {
      source.cancel();
    };
  }, [likes, dislikes, id, props.user.user_name]);

  return (
    <>
      <div className="single-post-header-container">
        <button className="back-button-style" onClick={history.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <h3 className="single-post-header">COMMUNITY OPINION</h3>
      </div>
      <CommunityOpinion
        post={props.post}
        vote={props.post.vote}
        topic_id={props.post.topic_id}
      />
      <PostCard
        post={props.post}
        likes={likes}
        dislikes={dislikes}
        setLikes={setLikes}
        setDislikes={setDislikes}
        user_image={props.user.photo_url}
        user_name={props.user.user_name}
        isLiked={isLiked}
        isDisliked={isDisliked}
        commentSubmit={commentSubmit}
        setCommentSubmit={setCommentSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ serverReducer }) => {
  return {
    user: serverReducer.user,
    isFetchingUserInfo: serverReducer.isFetchingUserInfo,
    post: serverReducer.post,
    isFetchingSinglePost: serverReducer.isFetchingSinglePost,
  };
};

export default connect(mapStateToProps, { getSinglePost, getUserInfo })(
  SinglePost
);
