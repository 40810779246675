import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosWithAuth from "../../utils/axiosWithAuth";

const OppositeOpinionUser = (props) => {
  const [userImages, setUserImages] = useState(
    "https://via.placeholder.com/150"
  );
  const [userName, setUserName] = useState();
  const { user_id } = props;

  // get user image
  useEffect(() => {
    axiosWithAuth()
      .get(`/api/users/${user_id}`)
      .then((res) => {
        setUserImages(res.data.photo_url);
        setUserName(res.data.user_name);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user_id]);

  function renderUserImage() {
    if (!userImages) {
      return (
        <img
          className="post-user-image"
          src="https://via.placeholder.com/150"
          alt="user profile pic"
        />
      );
    } else {
      return (
        <img
          className="post-user-image"
          src={userImages}
          alt="user profile pic"
        />
      );
    }
  }

  return (
    <>
      {renderUserImage()}
      <p className="post-body">
        <Link to={`/home/profile/${userName}`} className="username-link">
          {userName}
        </Link>
        <br />
        {props.post_body}
      </p>
    </>
  );
};

export default OppositeOpinionUser;
